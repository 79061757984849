import { Button, ButtonContainer } from "../../components/arraybutton/arraybutton";
import Card, { CardHeader, Heading } from "../../components/card/card";
import { useEffect, useState } from "react";
import { ConfigrationDevice, delAccessconfig } from "../../services/utilities/events";
import Mypopup from "../../components/popup/mypopup";
import AddAccessPopup from "../../components/accessPopup/Accesspopup";
import { ResponseMessageLocal } from "../../services/config/generic";
import Input from "../../components/input/input"; // Assuming you have an Input component
import { Sort } from "../../functions/functions";

const Access = (props) => {
    let [loader, setLoader] = useState(false);
    let [addAccess, setAddAccess] = useState(false);
    let [accessList, setAccessList] = useState([]);
    const [msgPopup, setMsgPopup] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // State for search term
    const [sortedUsers, setSortedUsers] = useState([]);
    const [sortType, setSortType] = useState('text-nam-asc'); // Default sort type
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 50, // Number of items per page
        pages: 0 // Total number of pages
    });
    const AccessGet = async () => {
        setLoader(true);
        let access = await ConfigrationDevice("access");
        setAccessList(access);
        setLoader(false);
    };

    useEffect(() => {
        AccessGet();
    }, []);

    const deleteAccess = async () => {
        let key = confirmPopup.key;
        let id = confirmPopup.data;
        await delAccessconfig(id);
        setAccessList(prevList => prevList.filter((item, index) => index !== key));
        setConfirmPopup(null);
    };

    // Filtered access list based on search term
    const filteredAccessList = accessList.filter(access => 
        access.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortData = (type, objkey) => {
        setSortType(type);
        const sorted = Sort(type, filteredAccessList, objkey);
        setSortedUsers(sorted);
    };

    useEffect(() => {
        // Set sortedUsers when accessList or searchTerm changes
        const sorted = Sort(sortType, filteredAccessList, 'label'); // Adjust the key if needed
        setSortedUsers(sorted);
    }, [accessList, searchTerm, sortType]);
   
    useEffect(() => {
        setSortedUsers(filteredAccessList);
        const totalPages = Math.ceil(filteredAccessList.length / pagination.pageSize);
        setPagination((prev) => ({ ...prev, pages: totalPages }));
    }, [accessList, searchTerm]);



    const startIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const paginatedData = sortedUsers.slice(startIndex, startIndex + pagination.pageSize);

    return (
        <div className='mt-2 px-3'>
            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader>
                    <div className='row'>
                        <div className='col'>
                            <Heading size={1} className="mr-3">Access</Heading>
                        </div>
                        
                        <div>
                            <div className='d-flex mr-1'>
                                <ButtonContainer>
                                    <Button onClick={() => setAddAccess("data")}>Add Access</Button>
                                </ButtonContainer>
                            </div>
                        </div>
                        <div className='col-12 mt-3'>
                            <Input
                                type="text"
                                label="Search Access"
                                placeholder="Search by label..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </CardHeader>
            </Card>
            {pagination &&
    <div className='d-flex w-100 justify-content-between align-items-center '>
         <div className= 'd-flex'>
           {startIndex + 1} to {startIndex + paginatedData.length} of {filteredAccessList.length} 
        </div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center m-0">
                {/* Previous button */}
                {pagination.currentPage > 1 ? (
                    <li className={"page-item px-1"}>
                        <button 
                            className="btn btn-light rounded-3" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                            Previous
                        </button>
                    </li>
                ) : (
                    <li className={"page-item px-1 disabled"}>
                        <button className="btn btn-light rounded-3" tabIndex="-1">Previous</button>
                    </li>
                )}

                {/* First page button */}
                <li className="page-item px-1">
                    <button 
                        onClick={() => setPagination({ ...pagination, currentPage: 1 })} 
                        className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                        1
                    </button>
                </li>

                {/* Ellipsis for pages not in view */}
                {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                {/* Dynamically generating buttons for nearby pages */}
                {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                    (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                    || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                    || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                ) ? (
                    <li key={pageNo} className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pageNo })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                            {pageNo}
                        </button>
                    </li>
                ) : null)}

                {/* Ellipsis for future pages */}
                {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                {/* Last page button */}
                {pagination.pages > 1 && (
                    <li className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                            {pagination.pages}
                        </button>
                    </li>
                )}

                {/* Next button */}
                {pagination.currentPage < pagination.pages ? (
                    <li className="page-item px-1">
                        <button 
                            className="btn btn-light rounded-3" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                            Next
                        </button>
                    </li>
                ) : (
                    <li className="page-item px-1 disabled">
                        <button className="btn btn-light rounded-3" tabIndex="-1">Next</button>
                    </li>
                )}
            </ul>
        </nav>
    </div>
}
            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className='row m-0 align-items-center'>
                    <div className='col'><Heading>S.No</Heading></div>
                    <div className='col' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-nam-asc' : 'text-nam-' + (sortType.includes('asc') ? 'des' : 'asc')), 'label')}>
                        <Heading className="d-flex gap-2">Name
                            <div className='ml-2'>
                                {sortType.includes('nam') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>
                    <div className='col-12 col-sm-4 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-valu-asc' : 'text-valu-' + (sortType.includes('asc') ? 'des' : 'asc')), 'value')}>
                        <Heading className="d-flex gap-2">Value
                            <div className='ml-2'>
                                {sortType.includes('valu') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>                   
                     <div className='col-2 d-flex justify-content-end' style={{ width: "100px" }}><Heading>Action</Heading></div>
                </CardHeader>
            </Card>
            
            {paginatedData.length > 0 ?
                paginatedData.map((access, key) =>
                    <Card className="overflow-hidden mt-1" key={access._id}>
                        <CardHeader className='row m-0 align-items-center'>
                            <div className='col'>{key + 1}</div>
                            <div className='col'>{access.label}</div>
                            <div className='col-12 col-sm-4 col-lg-2'>{access.value}</div>
                            <div className='col-2 d-flex justify-content-end' style={{ width: "100px" }}>
                                <ButtonContainer className="p-2 btn-light" flat="light">
                                    <Button onClick={() => setAddAccess(access)}>
                                        <i className="lt lt-edit"></i>
                                    </Button>
                                    <Button onClick={() => { setConfirmPopup({ data: access._id, key }) }}>
                                        <i className="lt lt-trash"></i>
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </CardHeader>
                    </Card>
                )
                : null
            }
             {pagination &&
    <div className='d-flex w-100 justify-content-between'>
         <div className= 'ml-2 mt-3 d-flex'>
           {startIndex + 1} to {startIndex + paginatedData.length} of {filteredAccessList.length} 
        </div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center px-2 pt-3">
                {/* Previous button */}
                {pagination.currentPage > 1 ? (
                    <li className={"page-item px-1"}>
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                            Previous
                        </button>
                    </li>
                ) : (
                    <li className={"page-item px-1 disabled"}>
                        <button className="btn btn-light rounded-circle" tabIndex="-1">Previous</button>
                    </li>
                )}

                {/* First page button */}
                <li className="page-item px-1">
                    <button 
                        onClick={() => setPagination({ ...pagination, currentPage: 1 })} 
                        className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                        1
                    </button>
                </li>

                {/* Ellipsis for pages not in view */}
                {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                {/* Dynamically generating buttons for nearby pages */}
                {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                    (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                    || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                    || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                ) ? (
                    <li key={pageNo} className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pageNo })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                            {pageNo}
                        </button>
                    </li>
                ) : null)}

                {/* Ellipsis for future pages */}
                {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                {/* Last page button */}
                {pagination.pages > 1 && (
                    <li className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                            {pagination.pages}
                        </button>
                    </li>
                )}

                {/* Next button */}
                {pagination.currentPage < pagination.pages ? (
                    <li className="page-item px-1">
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                            Next
                        </button>
                    </li>
                ) : (
                    <li className="page-item px-1 disabled">
                        <button className="btn btn-light rounded-circle" tabIndex="-1">Next</button>
                    </li>
                )}
            </ul>
        </nav>
    </div>
}
            {addAccess && (
                <Mypopup title={addAccess._id ? "Edit Role" : "Add Role"} onAddAccess={() => setAddAccess(null)} onClose={() => setAddAccess(null)}>
                    <AddAccessPopup data={addAccess} onResPopup={(e) => setMsgPopup(e)} onAddAccess={() => { setAddAccess(null); AccessGet() }} />
                </Mypopup>
            )}
            
            {confirmPopup && (
                <Mypopup title={"Confirm!"} onClose={() => setConfirmPopup(null)}>
                    <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteAccess(confirmPopup)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light" onClick={() => { setConfirmPopup(null); AccessGet() }}>No</button>
                    </div>
                </Mypopup>
            )}
        </div>
    );
};

export default Access;
