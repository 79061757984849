import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card';
import Select from '../../components/select/select';
import FullLoader from '../../components/loader/fullPageLoader';
import Mypopup from '../../components/popup/mypopup';
import { ButtonContainer, Button } from '../../components/arraybutton/arraybutton';
import { Sort, getDevicesList } from '../../functions/functions';
import InlineLoader from '../../components/loader/inlineLoader';
import { GetRoots, deleteHub, deviceDelete, getDevicesByLevelId, getHubByLevelId } from '../../services/utilities/events';
import Input from '../../components/input/input';
import AddHub from '../../components/addHubManagment/addhubmangment';
import { HubCreatedUrl } from '../../services/config/serverAddresses';
import { ResponseMessageLocal } from '../../services/config/generic';
import DeviceInfoPopup from '../../components/deviceInfoPopup/DeviceInfoPopup';
import HubInfoPopup from '../../components/hubInfoPopup/HubInfoPopup';
import moment from 'moment';

const HubManager = (props) => {
    const [state, setState] = useState({
        loader: true,
        deviceList: []
    });
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [inlineLoader, setInlineLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [deviceData, setDeviceData] = useState(null);
    const [addDevice, setAddDevice] = useState(false);
    const [rootLevel, setRootLevel] = useState([]);
    const [searchHubs, setSearchHubs] = useState("");
    const [levelId, setLevelId] = useState("");
    const [deviceInfoPopup, setDeviceInfoPopup] = useState(null); // State for DeviceInfoPopup
    const [msgPopup, setMsgPopup] = useState(null);
    const [sortedUsers, setSortedUsers] = useState([]);
    const [sortType, setSortType] = useState('date-dsc');
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 50, // Number of items per page
        pages: 0 // Total number of pages
    });
    const startIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const paginatedData = sortedUsers.slice(startIndex, startIndex + pagination.pageSize);
    useEffect(() => {
        const fetchData = async () => {
            await rootLevelGet();
        };
        fetchData();
    }, []);

    const onChangeHandler = async (e) => {
        setState((prevState) => ({
            ...prevState,
            loader: true
        }));

        let name = e.name;
        if (name === "organization") {
            const List = await getHubByLevelId(e.target.value);
            setLevelId(e.target.value);
            setState((prevState) => ({
                ...prevState,
                deviceList: List
            }));
        }

        setTimeout(() => {
            setState((prevState) => ({
                ...prevState,
                loader: false
            }));
        }, 2000);
    };

    let filterHubs = [];
    if (state.deviceList.length !== 0) {
        filterHubs = state?.deviceList?.filter(item => {
            if (item.name || item.type || item._id) {
                return searchHubs !== "" ? item._id.toLowerCase().includes(searchHubs.toLowerCase()) || item.levelId.toLowerCase().includes(searchHubs.toLowerCase()) || item.type.toLowerCase().includes(searchHubs.toLowerCase()) : item;
            }
        });
    }

    const DeviceGet = async () => {
        setState((prevState) => ({
            ...prevState,
            loader: true
        }));
        let devices = await getHubByLevelId(levelId);
        setState((prevState) => ({
            ...prevState,
            deviceList: devices,
            loader: false
        }));
        setAddDevice(null);
    };

    const rootLevelGet = async () => {
        let root = await GetRoots();
        let organization = [];
        root.length && root.map(org => {
            organization.push({ label: org, value: org });
        });

        setRootLevel(organization);
        setState((prevState) => ({
            ...prevState,
            loader: false
        }));
    };

    const addEditDevice = (data = null) => {
        if (data) {
            setAddDevice(data);
            setDeviceData(data);
        } else {
            setAddDevice(true);
            setDeviceData(null);
        }
    };

    const deleteaccess = async (id, key) => {
        await deleteHub(id);
        setConfirmPopup(null);
        await DeviceGet();
    };

    const getMacAddress = (id) => {
        const parts = id.split('_');
        return parts.length > 1 ? parts[1] : '';
    };

    const sortData = (type, objkey) => {
        setSortType(type)
        const sorted = Sort(type, filterHubs, objkey)
        console.log(sorted)
        setSortedUsers(sorted)
    }
    useEffect(() => {
        // Set sortedUsers when accessList or searchTerm changes
        const sorted = Sort(sortType, filterHubs, 'label'); // Adjust the key if needed
        setSortedUsers(sorted);
    }, [state.deviceList, searchHubs])
    useEffect(() => {
        // Set sortedUsers when filterHubs changes
        setSortedUsers(filterHubs);
        const totalPages = Math.ceil(filterHubs.length / pagination.pageSize);
        setPagination((prev) => ({ ...prev, pages: totalPages }));
    }, [state.deviceList, searchHubs]);


    return (
        <div className='mt-2 px-3'>
            <Card>
                <CardHeader>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <Heading size={1} className="mr-3">Hubs Management</Heading>
                        </div>
                        <div>
                            <div className='d-flex mr-1'>
                                <ButtonContainer>
                                    <Button onClick={() => addEditDevice()}>Add Hub</Button>
                                </ButtonContainer>
                            </div>
                        </div>
                    </div>
                </CardHeader>
                <div className='col-12 mb-2'>
                    <Select label="Select Organization" name="organization" onClick options={rootLevel} onChange={(e) => onChangeHandler(e)} />
                </div>
                
                {state.deviceList.length ?
                    <div className='col-12 mb-3'>
                        <Input label="Search Hub" name="search" onChange={(e) => setSearchHubs(e.target.value)} />
                    </div>
                    : null}
            </Card>
           
            {pagination &&
    <div className='d-flex w-100 justify-content-between align-items-center mt-3'>
         <div className= 'd-flex'>
           {startIndex + 1} to {startIndex + paginatedData.length} of {filterHubs.length} 
        </div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center m-0">
                {/* Previous button */}
                {pagination.currentPage > 1 ? (
                    <li className={"page-item px-1"}>
                        <button 
                            className="btn btn-light rounded-3" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                            Previous
                        </button>
                    </li>
                ) : (
                    <li className={"page-item px-1 disabled"}>
                        <button className="btn btn-light rounded-3" tabIndex="-1">Previous</button>
                    </li>
                )}

                {/* First page button */}
                <li className="page-item px-1">
                    <button 
                        onClick={() => setPagination({ ...pagination, currentPage: 1 })} 
                        className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                        1
                    </button>
                </li>

                {/* Ellipsis for pages not in view */}
                {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                {/* Dynamically generating buttons for nearby pages */}
                {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                    (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                    || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                    || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                ) ? (
                    <li key={pageNo} className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pageNo })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                            {pageNo}
                        </button>
                    </li>
                ) : null)}

                {/* Ellipsis for future pages */}
                {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                {/* Last page button */}
                {pagination.pages > 1 && (
                    <li className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                            {pagination.pages}
                        </button>
                    </li>
                )}

                {/* Next button */}
                {pagination.currentPage < pagination.pages ? (
                    <li className="page-item px-1">
                        <button 
                            className="btn btn-light rounded-3" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                            Next
                        </button>
                    </li>
                ) : (
                    <li className="page-item px-1 disabled">
                        <button className="btn btn-light rounded-3" tabIndex="-1">Next</button>
                    </li>
                )}
            </ul>
        </nav>
    </div>
}

            <Card className='overflow-hidden mt-3 mb-2'>
                <CardHeader className='row m-0 align-items-center'>
                <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-naam-asc' : 'text-naam-' + (sortType.includes('asc') ? 'des' : 'asc')), 'name')}>

<Heading className="d-flex gap-2">Mac Address
    <div className='ml-2'>

        {sortType.includes('naam') ?
            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
            :
            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
        }</div>
</Heading>
</div>
                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-type-asc' : 'text-type-' + (sortType.includes('asc') ? 'des' : 'asc')), 'type')}>

                        <Heading className="d-flex gap-2">Type
                            <div className='ml-2'>

                                {sortType.includes('type') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }</div>
                        </Heading>
                    </div>
                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-nam-asc' : 'text-nam-' + (sortType.includes('asc') ? 'des' : 'asc')), 'name')}>
                        <Heading className="d-flex gap-2">Name
                            <div className='ml-2'>

                                {sortType.includes('nam') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>
                    <div className='col-2 col-sm-3 col-lg-3' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-LC-asc' : 'text-LC-' + (sortType.includes('asc') ? 'des' : 'asc')), 'levelId')}>

                        <Heading className="d-flex gap-2">Location Name
                            <div className='ml-2'>

                                {sortType.includes('LC') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }</div>
                        </Heading>
                    </div>
                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('date') ? 'date-asc' : 'date-' + (sortType.includes('asc') ? 'des' : 'asc')), 'date')}>

                        <Heading className="d-flex gap-2">Created
                            <div className='ml-2'>

                                {sortType.includes('date') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }</div>
                        </Heading>
                    </div>
                    <div className='col-5 col-sm-3 col-lg-1 d-flex justify-content-end' style={{ width: "100px" }}><Heading align="end">Action</Heading></div>
                </CardHeader>
            </Card>
            {loader ? <FullLoader /> : paginatedData.length > 0 ?
                paginatedData.map((hub, key) =>
                    <Card className="overflow-hidden mt-1" key={hub._id}>
                        <CardHeader className='row m-0 align-items-center'>
                            <div className='col-3 col-sm-3 col-lg-2'>{getMacAddress(hub._id)}</div>
                            <div className='col-3 col-sm-3 col-lg-2'>{hub.type}</div>
                            <div className='col-2'>{hub.name}</div>
                            <div className='col-2 col-sm-3 col-lg-3'>{hub.levelId}</div>
                            <div className='col-2 col-sm-3 col-lg-2'>{moment(hub.created).format("ll LTS")}</div>
                            <div className='col-1 col-sm-3 col-lg-1 d-flex justify-content-end' style={{ width: "100px" }}>
                                <ButtonContainer className="p-2 btn-light" flat="light">
                                    <Button className='deviceBtn deviceRoundBtn btn'>
                                        <i className="fa fa-info" onClick={() => setDeviceInfoPopup(hub)}></i>
                                    </Button>
                                    <Button onClick={() => addEditDevice(hub)}>
                                        <i className="lt lt-edit"></i>
                                    </Button>
                                    <Button onClick={() => setConfirmPopup({ data: hub._id, key })}>
                                        <i className="lt lt-trash"></i>
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </CardHeader>
                    </Card>
                )
                : null}

{pagination &&
    <div className='d-flex w-100 justify-content-between'>
         <div className= 'ml-2 mt-3 d-flex'>
           {startIndex + 1} to {startIndex + paginatedData.length} of {filterHubs.length} 
        </div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center px-2 pt-3">
                {/* Previous button */}
                {pagination.currentPage > 1 ? (
                    <li className={"page-item px-1"}>
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                            Previous
                        </button>
                    </li>
                ) : (
                    <li className={"page-item px-1 disabled"}>
                        <button className="btn btn-light rounded-circle" tabIndex="-1">Previous</button>
                    </li>
                )}

                {/* First page button */}
                <li className="page-item px-1">
                    <button 
                        onClick={() => setPagination({ ...pagination, currentPage: 1 })} 
                        className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                        1
                    </button>
                </li>

                {/* Ellipsis for pages not in view */}
                {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                {/* Dynamically generating buttons for nearby pages */}
                {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                    (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                    || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                    || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                ) ? (
                    <li key={pageNo} className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pageNo })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                            {pageNo}
                        </button>
                    </li>
                ) : null)}

                {/* Ellipsis for future pages */}
                {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                {/* Last page button */}
                {pagination.pages > 1 && (
                    <li className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                            {pagination.pages}
                        </button>
                    </li>
                )}

                {/* Next button */}
                {pagination.currentPage < pagination.pages ? (
                    <li className="page-item px-1">
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                            Next
                        </button>
                    </li>
                ) : (
                    <li className="page-item px-1 disabled">
                        <button className="btn btn-light rounded-circle" tabIndex="-1">Next</button>
                    </li>
                )}
            </ul>
        </nav>
    </div>
}
            {addDevice ?
                <Mypopup title={addDevice?._id ? "Edit Hub" : "Add Hub"} onAddUser={() => setAddDevice(null)} onClose={() => { setAddDevice(null); }}>
                    <AddHub data={deviceData} onResPopup={(e) => setMsgPopup(e)} type={"direct"} onClose={() => { setAddDevice(null); DeviceGet(); }} onAddUser={() => { setAddDevice(null); DeviceGet(); }} />
                </Mypopup> :
                null
            }

            {confirmPopup ? (
                <Mypopup title={"Confirm!"} onClose={() => { setConfirmPopup(null) }}>
                    <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteaccess(confirmPopup.data, confirmPopup.key)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light" onClick={() => { setConfirmPopup(null) }}>No</button>
                    </div>
                </Mypopup>
            ) : null}

            {deviceInfoPopup ? (
                <HubInfoPopup res={deviceInfoPopup} onClose={() => setDeviceInfoPopup(null)} />
            ) : null}
        </div>
    );
}

export default HubManager;
