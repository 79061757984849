import React, { useState, useEffect } from 'react';
import './defaultLayout.scss';
import { checkSignIn, getCookie, getSignedInUser } from '../../services/utilities/users';
import { useDispatch, useSelector } from 'react-redux';
import { Verification } from '../../services/reducers/userInfo';
import { ADD_EVENT } from '../../services/reducers/eventReducer';
import { ADD_LOGS } from '../../services/reducers/logreducer';
import { ADD_NOTIFICATION } from '../../services/reducers/notification reducer';
import DefaultHeader from './defaultHeader';
import DefaultFooter from './defaultFooter';
import ConnectionStatus from '../../components/connection/connection';
import Sidebar from './sidebar';
import routes from '../../routes';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { USER_List } from '../../services/reducers/UserList';
import { getAdmins, getAllEvents, getConfigration } from '../../services/utilities/events';
import { ADD_CONFIG } from '../../services/reducers/configration';
import { getConfigrations } from '../../services/config/serverAddresses';
import { Live_Events, UPDATE_EVENTS, getEvents } from '../../services/reducers/lastEventReducer';
import getdata from '../../services/utilities/socket';
import { ADD_STATE } from '../../services/reducers/staetReducer';
import CardLoader from '../../components/loader/cardLoader';
import InlineLoader from '../../components/loader/inlineLoader';
import { ADD_ALERT } from '../../services/reducers/alertReducer';



export default function DefaultLayout(props) {
  const dispatch = useDispatch()
  const eventReducer = useSelector(state => state.deviceEvents)
  const eventReducer1 = useSelector(state => state.deviceLogs)
  const eventReducer2 = useSelector(state => state.varification)
  const eventReducer3 = useSelector(state => state.notifyEvents)
  const lastEvents = useSelector(state => state.lastEvents)
  const adminList = useSelector(state => state.fetchUserList)
  const alertlist = useSelector(state => state.alertReducer)
  const [network, setNetwork] = useState()
  const [sideToggle, setSideToggle] = useState(false)
  const [closeAllBtn, setCloseAllBtn] = useState(true)
  const [isLoader, setIsLoader] = useState(true)
  const history = useNavigate();
  let tamtoken = getCookie("token")


  useEffect(() => {
    if (tamtoken) {
    onloadFunc()
  }
  else {
    history('/login',{replace: true })
  }
  }, [])
  

  const onloadFunc = async () => {
    setIsLoader(true)
    let response = await getSignedInUser()
    let userlist = await getAdmins()
    let configuration = await getConfigration()
    dispatch(Verification(response))
    dispatch(USER_List(userlist))
    dispatch(ADD_CONFIG(configuration))

    let eventsName = ["deviceEvent", "logs", "state","credentials"]
    let client = getdata();
    eventsName.forEach(eventsName => {
      client.on(eventsName, body => {
        // let body1 = decrypt(body)
        // let body1 = body
        if (eventsName === "deviceEvent") {
          dispatch(UPDATE_EVENTS(body));
        }else if (eventsName === "deviceEvent" && Array.isArray(body)) {
          dispatch(Live_Events(body));
          dispatch(ADD_EVENT(body))
        } else if (eventsName === "logs") {
          dispatch(ADD_LOGS(body));
        } else if (eventsName === "state") {
          dispatch(ADD_STATE(body))
        }else if (eventsName === "credentials") {
          dispatch(ADD_ALERT(body))
        }
      })
    })
   
    setIsLoader(false)
  }
  
  const ConnectionsStatus = (e) => {
    if (e) {
      setNetwork(1)
    } else {
      setNetwork(0)
    }
  }
  return (isLoader ? <div><CardLoader></CardLoader></div>
    : <div>
      <div onClick={() => { setSideToggle(false); setCloseAllBtn(true) }}>
        <DefaultHeader organization={{}} network={network} />
      </div>
      <div>
        <div className={sideToggle ? "open menubtn" : "menubtn"}
          onClick={() => {
            setSideToggle(a => a = !sideToggle);
            setCloseAllBtn(true)
          }}
        ><i className={"fa fa-bars"}></i></div>

        <div className="position-relative">
          <div id="sideContainer" className={(sideToggle ? " open" : "")}>
            <Sidebar onOpenSideBar={(e) => { setSideToggle(e); setCloseAllBtn(false); }} onCloseBtn={closeAllBtn} />
          </div>
        </div>
        <main
          onClick={() => { setSideToggle(false); setCloseAllBtn(true) }}
        >
          <Routes>
            {routes.map((route, idx) => route.component ?
              <Route key={idx} path={route.path} exact={route.exact} name={route.name} element={<route.component />} />
              : null
            )}
          </Routes>
        </main>



      </div>
      <div>
        <ConnectionStatus ConnectionStatus={(e) => ConnectionsStatus(e)} />
      </div>
      <DefaultFooter />
    </div>)

}
