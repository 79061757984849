//import local Resources
import {
    signUpUrl,
    signInUrl,
    verifyEmailUrl,
    getSignedInUserUrl,
    userVerificationUrl,
    setPasswordUrl,
    resendEmailUrl,
    refreshTokenUrl,
    getConfigrations,
    signOutUrl,
    ForgotPasswordEmail,
    ForgetPassword,
    ChangePassword
} from '../config/serverAddresses';
import { postRequest, getRequest, putRequest } from './axiosRequests';
import { dataHandler } from '../../functions/functions';
import Cookies from 'js-cookie';

//Check SignIn
export let checkSignIn = () => {
    // return cookies.getCookie('token') ? true : false;
    return Cookies.get('token') ? true : false;
}
//Get Cookie By User
export const getCookie = (key) => {
    let name = key + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let decodedCookieParts = decodedCookie.split(';');


    for (let i = 0; i < decodedCookieParts.length; i++) {
        let c = decodedCookieParts[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}
//get LS 
export const getFromLS = () => {
    let localStorageid = localStorage.getItem("veriPass");
    return localStorageid !== null ? true : false
}

//SignOut User
export let signOut = async () => {
    try {
        let response = await getRequest(signOutUrl)
        let data = response.data
        Cookies.remove('token');
        Cookies.set("user", null, 0)
        window.location.reload()
        return data;
    } catch (err) {
       
    }
}
//UnAuthorize Signout
export let unAuthorizedSignout = async () => {
    try {
        let response = await getRequest(signOutUrl);
        let data = response.data;
        Cookies.remove("token");
        Cookies.set("user", null);
        return data; // Successfully signed out, return the data
    } catch (err) {
        // Check if the error response indicates a 401 Unauthorized error
        if (err.response && err.response.status === 401) {
            Cookies.remove("token");
            Cookies.set("user", null);
            window.location.href = "#/login?unauthorize=true";
            window.location.reload();
        } else {
            // Handle other errors if needed
            console.error("An error occurred:", err);
        }
    }
};

//Signup
export let signUp = async (Info) => {
    try {
        let response = await postRequest(signUpUrl, Info);

        let data = response.data
        data = dataHandler(response.data)
        return data;
    } catch (err) {
        // err.response.data.error = true;
        // return err.response.data;
    }
}
//getVerification
export let getVerification = async (code) => {
    try {
        let response = await getRequest(verifyEmailUrl + code);
        let data = response.data
        data = dataHandler(response.data)
        return data;
    } catch (err) {
        // err.response.data.error = true;
        // return err.response.data;
    }
}
//setPassword
export let setPassword = async (obj) => {
    try {
        let response = await postRequest(setPasswordUrl, obj);
        let data = response.data
        data = dataHandler(response.data)
        return data;
    } catch (err) {
        // err.response.data.error = true;
        // return err.response.data;
    }
}

//resendCode
export let resendCode = async (id) => {
    try {
        let response = await postRequest(resendEmailUrl, id);
        let data = response.data
        data = dataHandler(response.data)
        return data;
    } catch (err) {
        // err.response.data.error = true;
        // return err.response.data;
    }
}

//Request Forgot Password
export let requestForgotPasswordEmail = async (body) => {
    try {
        let response = await postRequest(ForgotPasswordEmail, body)
        let data = response.data
        data = dataHandler(response.data)
        return data;
    } catch (err) {
        // err.response.data.error = true
        // return err.response.data
    }
}

//Forget Password
export let ForgotPassword = async (body) => {
    try {
        let response = await postRequest(ForgetPassword, body)
        let data = response.data
        data = dataHandler(response.data)
        return data;
    } catch (err) {
        // err.response.data.error = true
        // return err.response.data
    }
}
// Change Password
export let ChangePass = async (body) => {
    try {
        let response = await putRequest(ChangePassword, body)
        let data = response.data
        data = dataHandler(response.data)
        return data;
    } catch (err) {
        // err.response.data.error = true
        // return err.response.data
    }
}
//refresh Token
// export let refreshToken = async (body) => {
//     try {
//         let response = await postRequest(refreshTokenUrl, body)
//         let data = await response.data
//         data = dataHandler(response.data)
//         if (data.tknExpiresIn) {
//             // const jwtToken = JSON.parse(window.atob(data.data.token.split('.')[1]));
//             let exp = data.tknExpiresIn
//             // cookies.setCookie('token', data.data.token, exp);
//             Cookie.set('expireTime', exp)
//             return data.data;
//         }
//         // else {
//         //     signOut()
//         // }
//     }
//     catch (err) {
//         signOut()
//         // err.response.data.error = true;
//         // return err.response.data;
//     }
// }
export let refreshToken = async (token) => {
    try {
        let response = await postRequest(refreshTokenUrl, token)
        let data = await response.data
        data = dataHandler(response.data)
        if (data.data.token) {
            const jwtToken = JSON.parse(window.atob(data.data.token.split('.')[1]));
            let exp = jwtToken.exp * 1000
            Cookies.set('token', data.data.token, exp);
            Cookies.set('user', jwtToken.firstName + " " + jwtToken.lastName, exp);
            Cookies.set('email', jwtToken.email)
            Cookies.set('exp', exp)
            return data.data;
        }
    }
    catch (err) {
        // signOut()
        // err.response.data.error = true;
        // return err.response.data;
    }
}
//SignIn
// export let signIn = async (credentials) => {

//     try {
//         let response = await postRequest(signInUrl, credentials);
//         let data = await response.data
//         data = dataHandler(response.data)
//         if (data.status === true) {
//             const jwtToken = JSON.parse(window.atob(data.data.token.split('.')[1]));
//             let exp = jwtToken.exp * 1000
//             cookies.setCookie('token', data.data.token, exp);
//             cookies.setCookie('user', jwtToken.firstName + " " + jwtToken.lastName, exp);
//             cookies.setCookie('email', jwtToken.email)
//             cookies.setCookie('exp', exp)
//             window.location.reload()
//             return jwtToken;
//         } else {
//             return response.data
//         }
//     } catch (err) {
//         err.response.data.error = true;
//         return err.response.data;
//     }
// }
// export let signIn = async (credentials, csrf) => {

//     try {
//         let response = await postRequest(signInUrl, credentials, csrf);
//         let data = await response.data
//         data = dataHandler(response.data)
//         if (data.status === true) {
//             // let status = cookies.getCookie("status")
//             // const jwtToken = JSON.parse(window.atob(data.data.token.split('.')[1]));
//             // let exp = jwtToken.exp * 1000
//             cookies.setCookie('status', data.status);
//             Cookie.set("expireTime", data.tknExpiresIn)
//             // cookies.setCookie('user', jwtToken.firstName + " " + jwtToken.lastName, exp);
//             // cookies.setCookie('email', jwtToken.email)
//             // cookies.setCookie('exp', exp)
//             window.location.reload()
//             // return status;
//         } else {
//             return response.data
//         }
//     } catch (err) {
//         // err.response.data.error = true;
//         // return err.response.data;
//     }
// }
export let signIn = async (credentials) => {

    // try {
    let response = await postRequest(signInUrl, credentials);
    let data = await response.data
    data = dataHandler(response.data)
    if (data.status === true) {
        const jwtToken = JSON.parse(window.atob(data.data.token.split('.')[1]));
        let exp = jwtToken.exp * 1000
        Cookies.set('token', data.data.token, exp);
        Cookies.set('user', jwtToken.firstName + " " + jwtToken.lastName, exp);
        Cookies.set('email', jwtToken.email)
        Cookies.set('exp', exp)
        window.location.reload()
        return jwtToken;
    } else {
        return response.data
    }
    // } catch (err) {
    //     err.response.data.error = true;
    //     return err.response.data;
    // }
}
export let getSignedInUser = async () => {
    try {
        let response = await getRequest(getSignedInUserUrl);
        let data = response.data
        data = dataHandler(response.data)
        return data;

    } catch (err) {
        err.response.data.error = true;
        return err.response.data;
    }
}
// export let singInGet = async () => {
//     try {
//         let response = await getRequest(signInUrl);
//         // data = dataHandler(response.data)
//         // let data = response
//         let data = dataHandler(response.data)
//         return data;
//     } catch (err) {
//         // err.response.data.error = true;
//         // return err.response.data;
//     }
// }

//User verification
export let userVerification = async (password) => {
    try {
        let response = await postRequest(userVerificationUrl, password);
        let data = response.data
        data = dataHandler(response.data)

        return data;

    } catch (err) {
        err.response.data.error = true;
        return err.response.data;
    }
}

//Configration By Device
export let getConfigration = async () => {
    try {
        let response = await getRequest(getConfigrations);
        // data = dataHandler(response.data)
        // let data = response
        let data = dataHandler(response.data)
        return data;
    } catch (err) {
        err.response.data.error = true;
        return err.response.data;
    }
}
// if (duplicateLevel?.length > 1) {
//     let latestList = [...duplicateLevel]
//     duplicateLevel.map(duplevel => {
//         levelsList.map(level => {
//             if (!level._id.match(duplevel._id)) {
//                 latestList.push({
//                     name: level.locationName,
//                     id: level._id,
//                     url: "/organizations/" + level._id,
//                     type: level.type,
//                     data: level
//                 })
//             }
//         })
//     })
//     levelsList = latestList
// }
//         })
//     }
// if (level._id === branch.levelId) {
//     duplicateLevel.push({
//         name: level.locationName,
//         id: level._id,
//         url: "/organizations/" + level._id,
//         type: level.type,
//         data: level,
//     })
// }