import React, { useState, useEffect } from "react";
import Card, { CardBody, CardHeader, Heading } from "../../components/card/card";
import PieConfig from "../../components/graph/pieConfig";
import { allnewUsers, unregistered, usercount, lastlogins } from "../../services/utilities/events";
import CardLoader from "../../components/loader/cardLoader";
import moment from "moment/moment";
import { SortItems } from "../../functions/functions";

function Dashboard() {
    const [data, setData] = useState([]);
    const [clickedCategory, setClickedCategory] = useState("Active Users"); // Default to "Active Users"
    const [filteredUserList, setFilteredUserList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortedUsers, setSortedUsers] = useState([]);
    const [sortType, setSortType] = useState('date-dsc');
    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await usercount();
                const getAllUsers = response?.[0] || {};
                const activeUsers = getAllUsers.active || 0;
                const inactiveUsers = getAllUsers.inactive || 0;
                const unregisteredUsers = getAllUsers.unregistered || 0;

                setData([
                    { category: "Active Users", value: activeUsers, color: "#28a745" },
                    { category: "Inactive Users", value: inactiveUsers, color: "#dc3545" },
                    { category: "Unregistered Users", value: unregisteredUsers, color: "#ffc107" }
                ]);

                // Fetch active users as default
                const activeUsersResponse = await allnewUsers("active");
                const lastLoginResponse = await lastlogins();
                const lastLoginMap = lastLoginResponse.reduce((acc, curr) => {
                    acc[curr.email] = curr.lastLoginCreatedOn;
                    return acc;
                }, {});

                const combinedData = activeUsersResponse.map(user => ({
                    ...user,
                    lastLogin: lastLoginMap[user.email] || null
                }));

                setFilteredUserList(combinedData); // Set active users as default list
            } catch (error) {
                console.error("Error fetching user data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleSliceClick = async (category) => {
        setClickedCategory(category);
        setLoading(true);

        try {
            let users = [];
            if (category === "Active Users" || category === "Inactive Users") {
                const userType = category === "Active Users" ? "active" : "inactive";
                const response = await allnewUsers(userType);
                users = response || [];

                // Fetch the last login data for both active and inactive users
                const lastLoginResponse = await lastlogins();
                const lastLoginMap = lastLoginResponse.reduce((acc, curr) => {
                    acc[curr.email] = curr.lastLoginCreatedOn;
                    return acc;
                }, {});

                // Map the lastLogin data to users
                const combinedData = users.map(user => ({
                    ...user,
                    lastLogin: lastLoginMap[user.email] || null
                }));

                setFilteredUserList(combinedData);
            }
            else {
                if (category === "Unregistered Users") {
                    const response = await unregistered("Unregistered");
                    users = response || [];
                }

                const usersWithoutLastLogin = users.map(user => ({
                    ...user,
                    lastLogin: undefined
                }));

                setFilteredUserList(usersWithoutLastLogin);
            }
        } catch (error) {
            console.error("Error fetching user list", error);
        } finally {
            setLoading(false);
        }
    };

    console.log(filteredUserList)
    const sortData = (type, objkey) => {
        setSortType(type);
        const sorted = SortItems(type, filteredUserList, objkey);
        setSortedUsers(sorted);  // Update sorted users state
    };

    useEffect(() => {
        setSortedUsers(filteredUserList);
    }, [filteredUserList]);
    
    return (
        <div className="row justify-content-center m-0 pt-2">
            <div className="col-12 mb-2">

                <Card theme="d-flex primary-gradient p-1 mx-1 mx-xl-2 ">

                    <Heading className="headerName" color="white" size={1}>Operations</Heading>
                </Card>

                <Card className="mt-2">
                    <CardBody className="d-flex flex-column">
                        <div className="row mb-3">
                            <div className="col-sm-12 col-md-4 mb-4">
                                <Heading className="ml-5">User Status</Heading>
                                <div className="d-flex justify-content-center">
                                    {loading ? (
                                        <CardLoader />
                                    ) : (
                                        <PieConfig
                                            data={data}
                                            chart={{
                                                id: "userStatusPieChart",
                                                x: "category",
                                                y: "value",
                                                graphcolorFromData: true,
                                                legend: true,
                                                tooltip: true, // Display only the number of users in the tooltip
                                                stroke: true,
                                                fill: true,
                                                labels: {
                                                    visible: true,
                                                    format: "{y}", // Display the user count (value) in the pie chart
                                                },
                                                label: {
                                                    text: "{value}", // Display only the value (number) inside the slice
                                                },
                                            }}
                                            onSliceClick={handleSliceClick}
                                        />

                                    )}
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-8">
                                {clickedCategory && (
                                    <>
                                        <Card>
                                            <Heading className="ml-3 mt-2">
                                                {clickedCategory}
                                            </Heading>
                                        </Card>

                                        {/* Align table headers and list items */}
                                        <Card className="mt-1">
                                            <div className="row mt-3" style={{ fontWeight: 'bold' }}>
                                                <div className="col-2 col-lg-1 ml-4" >SNo</div>
                                                <div className='col-2 col-sm-3 col-lg-4' style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('name') ? 'name-asc' : 'name-' + (sortType.includes('asc') ? 'des' : 'asc'))}>
                                                    <Heading className="d-flex gap-2">Email
                                                        <div className='ml-2'>
                                                            {sortType.includes('name') ?
                                                                <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                                :
                                                                <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                            }
                                                        </div>

                                                    </Heading>

                                                </div>
                                                <div className='col-2 col-sm-3 col-lg-3' style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('name') ? 'name-asc' : 'name-' + (sortType.includes('asc') ? 'des' : 'asc'))}>
                                                    <Heading className="d-flex gap-2">Name
                                                        <div className='ml-2'>
                                                            {sortType.includes('name') ?
                                                                <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                                :
                                                                <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                            }
                                                        </div>

                                                    </Heading>

                                                </div>                                     {clickedCategory === "Active Users" || clickedCategory === "Inactive Users" ? (
                                                    <div className="col-1 col-sm-1 col-lg-3" style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('date') ? 'date-asc' : 'date-' + (sortType.includes('asc') ? 'des' : 'asc'))}>
                                                        <Heading className="d-flex gap-2">lastlogin
                                                            <div className='ml-2'>
                                                                {sortType.includes('date') ?
                                                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                                    :
                                                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                                }
                                                            </div>

                                                        </Heading>

                                                    </div>) : (
                                                    clickedCategory === "Unregistered Users" && (
                                                        <div className="col-1 col-sm-1 col-lg-3" style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('date') ? 'date-asc' : 'date-' + (sortType.includes('asc') ? 'des' : 'asc'))}>
                                                            <Heading className="d-flex gap-2">Created
                                                                <div className='ml-2'>
                                                                    {sortType.includes('date') ?
                                                                        <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                                                        :
                                                                        <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                                                    }
                                                                </div>

                                                            </Heading>

                                                        </div>)
                                                )}
                                            </div>
                                        </Card>

                                        {/* List of users */}
                                        <Card className="mr-2 mt-2" style={{ maxHeight: "450px", overflowY: "scroll", overflowX: "hidden" }}>
                                            <CardBody>
                                                <div className="list-group mt-2">
                                                    {loading ? (
                                                        <CardLoader />
                                                    ) : (
                                                        sortedUsers.map((user, index) => (
                                                            <Card className="overflow-hidden mt-1" key={index}>
                                                                <CardHeader className='row m-0 align-items-center'>
                                                                    <div className="col-2 col-lg-1">{index + 1}</div>
                                                                    <div className="col-5 col-lg-4">{user.email}</div>
                                                                    <div className="col-5 col-lg-3">{user.firstName} {user.lastName}</div>
                                                                    {clickedCategory === "Active Users" || clickedCategory === "Inactive Users" ? (
                                                                        <div className="col-4 col-lg-4">
                                                                            {user.lastLogin ? moment(user.lastLogin).format("ll LTS").toLocaleString() : "N/A"}
                                                                        </div>
                                                                    ) : (
                                                                        clickedCategory === "Unregistered Users" && (
                                                                            <div className="col-4 col-lg-4">
                                                                                {user.created ? moment(user.created).format("ll LTS").toLocaleString() : "N/A"}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </CardHeader>
                                                            </Card>
                                                        ))
                                                    )}
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </>
                                )}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default Dashboard;
