import React, { useEffect, useState } from 'react';
import { BusinessCategory, organizationsType } from '../../services/config/generic';
import { Heading } from '../../components/card/card';
import Select from '../../components/select/select';
import Input from '../../components/input/input';
import { fetchData } from '../../functions/functions';
import { addLevelOrganizationUpdate } from '../../services/utilities/events';
import CardLoader from '../../components/loader/cardLoader';
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton';
import { SpecialCharacters, WhiteSpace } from '../../services/config/regex';

const AddLevelPopup = (props) => {
    const [state, setState] = useState({
        loginData: {},
        modal: false,
        redirect: false,
        loginLoader: false,
    });
    const [metadataError, setMetadataError] = useState(null);
    const [hierarchyLoader, setHeirarchyLoader] = useState(true);
    const [level, setLevel] = useState({
        metadata: {
            coordinates: {
                lat: 0,
                lng: 0,
            },
            overview: [],
        },
    });

    const [branchLevel, setBranchLevel] = useState([]);
    const [levelLoader, setLevelLoader] = useState(false);

    useEffect(() => {
        if (props.data?._id) {
            let data1 = { ...props.data };
            if (data1.level === 1) {
                data1['type'] = 'organization';
            } else {
                data1['type'] = 'level';
                data1['organization'] = data1.levelId.split('_')[0];
                callData(data1.organization);
            }
            setLevel(data1);
        }
    }, [props.data]);

    const onChangeHandler = (e) => {
        const level1 = { ...level };
        let name = e.name;
        let value = e.target.value;
        level1[name] = value;
        if (name === 'organization') {
            callData(level1.organization);
        }
        if (name === 'metadataLat' || name === 'metadataLng') {
            const coordinateKey = name === 'metadataLat' ? 'lat' : 'lng';
            level1.metadata.coordinates[coordinateKey] = value;
        }
        setLevel(level1);
    };

    const addLevelOrganization = async () => {
        let state1 = { ...state };
        if (!('_id' in level)) {
            level['_id'] = level?.name ? level.name.replaceAll(WhiteSpace, '') : level.locationName.replaceAll(WhiteSpace, '');
            level._id = level._id.replaceAll(SpecialCharacters, '');
        }
        await addLevelOrganizationUpdate(props.data._id ? props.data._id : null, level).then(async (res) => {
            let result = res;
            let message = res.message;
            if (result) {
                props.onAddUser(['Success!', props.data._id ? 'Successfully Updated.' : 'Successfully Created.', 'success']);
                state1.loginLoader = false;
            } else {
                props.onAddUser(['Failure!', message, 'danger']);
                state1.loginLoader = false;
            }
        });
    };

    const callData = async (level) => {
        const branch = await fetchData(level);
        setBranchLevel(branch);
        setHeirarchyLoader(false);
    };

    const handleChangeMetadata = (e) => {
        const { value } = e.target;
        try {
            const parsedValue = JSON.parse(value);
            setMetadataError(null); // Clear error if valid JSON
            setLevel((prevLevel) => ({
                ...prevLevel,
                metadata: parsedValue, // Update metadata without nesting
            }));
        } catch (error) {
            setMetadataError('Invalid JSON format. Please make sure your input is valid JSON.');
        }
    };
    return (
        <div className='px-3 pb-3'>
            <div>
                <div className='d-flex justify-content-center'>
                    <Heading size={2}>Location Information</Heading>
                </div>

                <div className='row'>
                    <div className='col-md-12 mt-3'>
                        <Select label='Type*' name='type' onClick options={organizationsType} defaultValue={{ value: level.type }} onChange={onChangeHandler} />
                    </div>
                </div>
                {level.type === 'level' ? (
                    <div className='row'>
                        <div className='col-md-6 mt-3'>
                            <Input label='Name*' name='locationName' onClick defaultValue={level.locationName} onChange={onChangeHandler} />
                        </div>
                        <div className='col-md-6 mt-3'>
                            <Select label='Organization*' name='organization' onClick options={props.organizationName} defaultValue={{ value: level.organization }} onChange={onChangeHandler} />
                        </div>
                        {!hierarchyLoader && (
                            <>
                                <div className='col-md-12 mt-3'>
                                    <Select label='LevelId*' name='levelId' onClick options={branchLevel} defaultValue={{ value: level.levelId }} onChange={onChangeHandler} />
                                </div>
                                <div className='col-md-6 col-12 mt-3'>
                                    <Input name='contactName' label='Contact person name*' defaultValue={level.contactName} onChange={onChangeHandler} />
                                </div>
                                <div className='col-md-6 col-12 mt-3'>
                                    <Input name='contactNumber' label='Contact person number*' type='number' defaultValue={level.contactNumber} onChange={onChangeHandler} />
                                </div>
                              
                                <div className='col-12 mt-3'>
                            <Input
                                type='textarea'
                                defaultValue={JSON.stringify(level?.metadata, null, 2)}
                                onChange={handleChangeMetadata}
                                label='Enter JSON'
                                message={{
                                    msg: metadataError,
                                    status: metadataError,
                                }}
                            />
                        </div>      
                            </>
                        )}
                    </div>
                ) : null}
                {level.type === 'organization' ? (
                    <div className='row'>
                        <div className='col-md-6 mt-3'>
                            <Input label='Name*' name='name' onClick defaultValue={level.name} onChange={onChangeHandler} />
                        </div>
                        <div className='col-md-6 mt-3 col-12'>
                            <Input name='companyUrl' label='Company URL*' defaultValue={level.companyUrl} onChange={onChangeHandler} className='text-primary' />
                        </div>
                        <div className='col-md-6 my-3 col-12'>
                            <Select name='organizationBusiness' label='Organization Business*' defaultValue={{ value: level.organizationBusiness }} onClick options={BusinessCategory} onChange={onChangeHandler} />
                        </div>
                        <div className='col-md-6 mt-3 col-12'>
                            <Input name='addressOfHeadquater' label='Address*' defaultValue={level.addressOfHeadquater} onChange={onChangeHandler} />
                        </div>
                        
                        <div className='col-12 mt-3'>
                            <Input
                                type='textarea'
                                defaultValue={JSON.stringify(level?.metadata, null, 2)}
                                onChange={handleChangeMetadata}
                                label='Enter JSON'
                                height="200px"
                                message={{
                                    msg: metadataError,
                                    status: metadataError,
                                }}
                            />
                        </div>
                    </div>
                ) : null}
                {levelLoader ? <CardLoader /> : <ButtonContainer className='mt-3'>
                    <Button onClick={addLevelOrganization}>{props.data?._id ? 'Save' : 'Add'}</Button>
                </ButtonContainer>}
            </div>
        </div>
    );
};

export default AddLevelPopup;
