import { useSelector } from "react-redux";
import { Button, ButtonContainer } from "../../components/arraybutton/arraybutton";
import Card, { CardHeader, Heading } from "../../components/card/card";
import { useEffect, useState } from "react";
import { GetAdminRoles, Harddelroles, delRoleconfig } from "../../services/utilities/events";
import Mypopup from "../../components/popup/mypopup";
import AddrolePopup from "../../components/RolePopup/RolePopup";
import Input from "../../components/input/input"; // Assuming you have an Input component
import { ResponseMessageLocal, ResponseMessageLocalpermenant } from "../../services/config/generic";
import { Sort } from "../../functions/functions";

const Devices = (props) => {
    const [loader, setLoader] = useState(false);
    const [addRole, setAddRole] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [msgPopup, setMsgPopup] = useState(null);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [confirmedPopup, setConfirmedPopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // State for search term
    const configuration = useSelector(state => state.configuration);
    const [sortedUsers, setSortedUsers] = useState([]);
    const [sortType, setSortType] = useState('date-dsc');
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 50, // Number of items per page
        pages: 0 // Total number of pages
    });
    const RolesGet = async () => {
        setAddRole(null);
        setLoader(true);
        let roles = await GetAdminRoles();
        const role = matchConfigurationWithName(roles, configuration);
        const role1 = role.reverse();
        setRoleList(role1);
        setLoader(false);
    }

    useEffect(() => {
        RolesGet();
    }, []);

    const matchConfigurationWithName = (roleList, configuration) => {
        return roleList.map(role => {
            return {
                ...role,
                access: role.access.map(accessItem => {
                    const matchingConfig = configuration.config.access.find(configItem => configItem._id === accessItem);
                    return {
                        _id: accessItem,
                        label: matchingConfig ? matchingConfig.label : "Unknown Configuration"
                    };
                })
            };
        });
    }

    const deleteAccess = async (id, key) => {
        await delRoleconfig(id);
        setRoleList(prevList => prevList.filter((item, index) => index !== key));
        setConfirmPopup(null);
    }

    const deleteRoles = async (id, key) => {
        await Harddelroles(id);
        setRoleList(prevList => prevList.filter((item, index) => index !== key));
        setConfirmPopup(null);
    }

    // Filtered role list based on search term
    const filteredRoleList = roleList.filter(role =>
        role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const sortData = (type, objkey) => {
        setSortType(type)
        const sorted = Sort(type, filteredRoleList, objkey)
        console.log(sorted)
        setSortedUsers(sorted)
    }
    useEffect(() => {
        // Set sortedUsers when filterHubs changes
        setSortedUsers(filteredRoleList);
    }, [roleList, searchTerm]);
    useEffect(() => {
        setSortedUsers(filteredRoleList);
        const totalPages = Math.ceil(filteredRoleList.length / pagination.pageSize);
        setPagination((prev) => ({ ...prev, pages: totalPages }));
    }, [roleList, searchTerm]);



    const startIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const paginatedData = sortedUsers.slice(startIndex, startIndex + pagination.pageSize);

    return (
        <div className='mt-2 px-3'>
            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader>
                    <div className='row'>
                        <div className='col'>
                            <Heading size={1} className="mr-3">Roles</Heading>
                        </div>
                        
                      
                        <div className='d-flex mr-1'>
                            <ButtonContainer>
                                <Button onClick={() => setAddRole("data")}>Add Roles</Button>
                            </ButtonContainer>
                        </div>
                        <div className='col-12 mt-3'>
                            <Input
                                type="text"
                                placeholder="Search by name..."
                                label="Search Role"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </CardHeader>
            </Card>
            {pagination &&
    <div className='d-flex w-100 justify-content-between align-items-center '>
         <div className= 'd-flex'>
           {startIndex + 1} to {startIndex + paginatedData.length} of {filteredRoleList.length} 
        </div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center m-0">
                {/* Previous button */}
                {pagination.currentPage > 1 ? (
                    <li className={"page-item px-1"}>
                        <button 
                            className="btn btn-light rounded-3" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                            Previous
                        </button>
                    </li>
                ) : (
                    <li className={"page-item px-1 disabled"}>
                        <button className="btn btn-light rounded-3" tabIndex="-1">Previous</button>
                    </li>
                )}

                {/* First page button */}
                <li className="page-item px-1">
                    <button 
                        onClick={() => setPagination({ ...pagination, currentPage: 1 })} 
                        className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                        1
                    </button>
                </li>

                {/* Ellipsis for pages not in view */}
                {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                {/* Dynamically generating buttons for nearby pages */}
                {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                    (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                    || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                    || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                ) ? (
                    <li key={pageNo} className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pageNo })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                            {pageNo}
                        </button>
                    </li>
                ) : null)}

                {/* Ellipsis for future pages */}
                {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                {/* Last page button */}
                {pagination.pages > 1 && (
                    <li className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                            {pagination.pages}
                        </button>
                    </li>
                )}

                {/* Next button */}
                {pagination.currentPage < pagination.pages ? (
                    <li className="page-item px-1">
                        <button 
                            className="btn btn-light rounded-3" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                            Next
                        </button>
                    </li>
                ) : (
                    <li className="page-item px-1 disabled">
                        <button className="btn btn-light rounded-3" tabIndex="-1">Next</button>
                    </li>
                )}
            </ul>
        </nav>
    </div>
}
            {/* Search input field */}


            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className='row m-0 align-items-center'>
                    <div className='col-2 col-sm-2  col-lg-1'><Heading>S.No</Heading></div>
                    <div className='col-2 col-sm-3 col-lg-3' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-nam-asc' : 'text-nam-' + (sortType.includes('asc') ? 'des' : 'asc')), 'name')}>
                        <Heading className="d-flex gap-2">Roles
                        
                            <div className='ml-2'>

                                {sortType.includes('nam') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>
                        <div className='col-3 col-sm-3 col-lg-4'><Heading>Access</Heading></div>
                        <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-CF-asc' : 'text-CF-' + (sortType.includes('asc') ? 'des' : 'asc')), 'createdFor')}>
                        <Heading className="d-flex gap-2">Created For
                            <div className='ml-2'>

                                {sortType.includes('CF') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>                    <div className='col-2 col-sm-2  col-lg-2 d-flex justify-content-end'><Heading>Action</Heading></div>
                </CardHeader>
            </Card>

           {paginatedData.length > 0 ?
    paginatedData.filter(role => !role.isDeleted).map((roles, key1) => (
        <Card className="overflow-hidden mt-1" key={roles._id}>
            <CardHeader className='row m-0 align-items-center'>
                <div className='col-1 col-sm-2 col-lg-1'>{key1 + 1}</div>
                <div className='col-2 col-sm-2 col-lg-3'>{roles.name}</div>
                <div className='col-2 col-sm-3 col-lg-4'>
                    {roles.access.length ? roles.access.map((acc, index) => (
                        <span key={index}>{acc.label}</span> // Add a unique key here
                    )) : "Access Not Available"}
                </div>
                <div className='col-3 col-sm-3 col-lg-2'>{roles.createdFor ? roles.createdFor : "Generic"}</div>
                <div className='col-2 col-sm-2 col-lg-2 d-flex justify-content-end' style={{ width: "100px" }}>
                    <ButtonContainer className="p-2 btn-light" flat="light">
                        <Button>
                            <i className="lt lt-edit" onClick={() => setAddRole(roles)}></i>
                        </Button>
                        <Button>
                            <i className="lt lt-trash" onClick={() => setConfirmPopup({ data: roles._id, key1 })}></i>
                        </Button>
                        <Button>
                            <i className="lt lt-trash text-danger" onClick={() => setConfirmedPopup({ data: roles._id, key1 })}></i>
                        </Button>
                    </ButtonContainer>
                </div>
            </CardHeader>
        </Card>
    ))
    : null}

{pagination &&
    <div className='d-flex w-100 justify-content-between'>
         <div className= 'ml-2 mt-3 d-flex'>
           {startIndex + 1} to {startIndex + paginatedData.length} of {filteredRoleList.length} 
        </div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center px-2 pt-3">
                {/* Previous button */}
                {pagination.currentPage > 1 ? (
                    <li className={"page-item px-1"}>
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                            Previous
                        </button>
                    </li>
                ) : (
                    <li className={"page-item px-1 disabled"}>
                        <button className="btn btn-light rounded-circle" tabIndex="-1">Previous</button>
                    </li>
                )}

                {/* First page button */}
                <li className="page-item px-1">
                    <button 
                        onClick={() => setPagination({ ...pagination, currentPage: 1 })} 
                        className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                        1
                    </button>
                </li>

                {/* Ellipsis for pages not in view */}
                {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                {/* Dynamically generating buttons for nearby pages */}
                {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                    (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                    || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                    || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                ) ? (
                    <li key={pageNo} className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pageNo })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                            {pageNo}
                        </button>
                    </li>
                ) : null)}

                {/* Ellipsis for future pages */}
                {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                {/* Last page button */}
                {pagination.pages > 1 && (
                    <li className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                            {pagination.pages}
                        </button>
                    </li>
                )}

                {/* Next button */}
                {pagination.currentPage < pagination.pages ? (
                    <li className="page-item px-1">
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                            Next
                        </button>
                    </li>
                ) : (
                    <li className="page-item px-1 disabled">
                        <button className="btn btn-light rounded-circle" tabIndex="-1">Next</button>
                    </li>
                )}
            </ul>
        </nav>
    </div>
}

            {addRole && (
                <Mypopup title={addRole._id ? "Edit Role" : "Add Role"} onAddRole={() => setAddRole(null)} onClose={() => setAddRole(null)}>
                    <AddrolePopup data={addRole} onResPopup={(e) => setMsgPopup(e)} onAddRole={() => { setAddRole(null); RolesGet() }} />
                </Mypopup>
            )}

            {confirmPopup && (
                <Mypopup title={"Confirm!"} onClose={() => setConfirmPopup(null)}>
                    <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteAccess(confirmPopup.data, confirmPopup.key)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light" onClick={() => setConfirmPopup(null)}>No</button>
                    </div>
                </Mypopup>
            )}

            {confirmedPopup && (
                <Mypopup title={"Confirm!"} onClose={() => setConfirmedPopup(null)}>
                    <p className="p-3">{ResponseMessageLocalpermenant.confirmMessageHardDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteRoles(confirmedPopup.data, confirmedPopup.key)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light" onClick={() => setConfirmedPopup(null)}>No</button>
                    </div>
                </Mypopup>
            )}
        </div>
    )
}

export default Devices;
