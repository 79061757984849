import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

export const alertReducer = createSlice({
    name: 'ALERT',
    initialState,
    reducers: {
        ADD_ALERT: (state, action) => {
            state.alert1 = action.payload
        }
    }
})
export const { ADD_ALERT } = alertReducer.actions

export default alertReducer.reducer