import React, { useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const PieConfig = (props) => {
    const chartDiv = useRef(null);
    let chart;

    const createChart = () => {
        am4core.useTheme(am4themes_animated);

        chart = am4core.create(chartDiv.current, am4charts.PieChart);
        chart.padding(0, 0, 0, 0);

        let series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.category = props.chart.x;
        series.dataFields.value = props.chart.y;

        if ('graphcolorFromData' in props.chart && props.chart.graphcolorFromData) {
            series.slices.template.propertyFields.fill = "color";
        } else {
            series.colors.list = [am4core.color(props.chart.graphcolor)];
        }

        series.slices.template.stroke = am4core.color("#fff");
        series.slices.template.strokeWidth = props.chart.stroke ? 1 : 0;
        series.slices.template.strokeOpacity = props.chart.stroke ? 1 : 0;
        series.fillOpacity = props.chart.fill ? 1 : 0;

        series.hiddenState.properties.opacity = 0;
        series.hiddenState.properties.endAngle = -90;
        series.hiddenState.properties.startAngle = -90;

        // Disable tooltips if specified
        if ("tooltip" in props.chart && !props.chart.tooltip) {
            series.tooltip.disabled = true;
        }

        // Set up the legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";
        chart.legend.labels.template.text = "{category}: {value}"; // Show category and value in the legend

        // Remove percentage labels and only show the value
        series.labels.template.disabled = true; // Disable labels
        series.ticks.template.disabled = true; // Disable ticks

        // Add custom label to show only the number inside slices
        series.slices.template.label = new am4core.Label();
        series.slices.template.label.text = "{value}"; // Show the number of users
        series.slices.template.label.fill = am4core.color("#fff"); // Optional: Set label color
        series.slices.template.label.fontSize = 12; // Optional: Set label font size
        series.slices.template.label.horizontalCenter = "middle"; // Center the label
        series.slices.template.label.verticalCenter = "middle"; // Center the label

        // Add event listener for slice clicks
        series.slices.template.events.on("hit", (ev) => {
            const clickedCategory = ev.target.dataItem.category;
            if (props.onSliceClick) {
                props.onSliceClick(clickedCategory);
            }
        });
    };

    useEffect(() => {
        createChart();
        chart.data = props.data;

        return () => {
            if (chart) {
                chart.dispose();
            }
        };
    }, [props.data]);

    return (
        <div className="overflow-hidden">
            <div
                className="w-100"
                style={{
                    height: "370px", // Set a fixed height for the chart
                    marginBottom: "-18px"
                }}
                id={props.chart.id}
                ref={chartDiv}
            ></div>
        </div>
    );
};

export default PieConfig;
