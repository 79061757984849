import React, { useEffect, useRef, useState } from 'react'
import CardLoader from '../../components/loader/cardLoader'
import Card, { CardBody, CardHeader, Heading, InnerCard } from '../../components/card/card'
import Select from '../../components/select/select'
import { GetHubs, GetRoots, getAllEvents, getHubsByLevelId } from '../../services/utilities/events'
import { Sort, SortItems, getDate, getTime } from '../../functions/functions'
import { useDispatch, useSelector } from 'react-redux'
import { Get_Events } from '../../services/reducers/lastEventReducer'
import moment from 'moment'
import Modal from './tab/modal'
import Mypopup from '../../components/popup/mypopup'
import Input from '../../components/input/input'
import { Button } from '../../components/arraybutton/arraybutton'
const MQTT = (props) => {
    const allHubsOrignal = useRef([])
    const [popup, setPopup] = useState(null)
    const [searchHubs, setSearchHubs] = useState('')
    const dispatch = useDispatch()
    const lastEvents = useSelector(state => state.getLastEvent)
    const [sortedUsers, setSortedUsers] = useState([]);
    const [sortType, setSortType] = useState('date-dsc');
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 50, // Number of items per page
        pages: 0 // Total number of pages
    });
    // const [loader, setLoader] = useState(true)
    const [rootLevel, setRootLevel] = useState([])
    const [id, setId] = useState()


    const [state, setState] = useState({
        loader: true,
        hubList: []
    })

    const fetchEvents = async () => {
        const response = await getAllEvents();
        dispatch(Get_Events(response));
    };

    const allHubs = async () => {
        const response = await GetHubs()
        allHubsOrignal.current = response
        console.log(response)
        setState((prevState) => ({
            ...prevState,          // Spread the previous state to keep other properties
            hubList: response           // Set the loader state to false
        }));
    }
    useEffect(() => {
        const fetchData = async () => {
            setState((prevState) => ({
                ...prevState,          // Spread the previous state to keep other properties
                loader: true           // Set the loader state to false
            }));
            await rootLevelGet();
            await allHubs()
            await fetchEvents();
            setState((prevState) => ({
                ...prevState,          // Spread the previous state to keep other properties
                loader: false           // Set the loader state to false
            }));
        };
        fetchData();
    }, []);


    const rootLevelGet = async () => {
        let root = await GetRoots()
        let organization = []
        organization.push({ label: 'All', value: 'all' })
        root.length && root.map(org => {
            organization.push({ label: org, value: org })
        })

        setRootLevel(organization)
        setState((prevState) => ({
            ...prevState,          // Spread the previous state to keep other properties
            loader: false           // Set the loader state to false
        }));
    }


    const onChangeHandler = async (e) => {
        // setState((prevState) => ({
        //     ...prevState,          // Spread the previous state to keep other properties
        //     loader: false           // Set the loader state to false
        // }));

        let name = e.name
        if (name === "organization") {
            // const List = await getHubsByLevelId(e.target.value)
            const List = e.target.value === 'all' ? allHubsOrignal.current : allHubsOrignal.current.map((hubData) => {
                const OrgId = hubData._id.split('_')[0]
                if (OrgId === e.target.value) {
                    return hubData
                }
            }).filter((hubData) => hubData !== undefined)
            setId(e.target.value)
            setState((prevState) => ({
                ...prevState,          // Spread the previous state to keep other properties
                hubList: List           // Set the loader state to false
            }));
        }

        // setTimeout(() => {
        //     setState((prevState) => ({
        //         ...prevState,          // Spread the previous state to keep other properties
        //         loader: false           // Set the loader state to false
        //     }));
        // }, 2000)
    }
    const OpenModal = (hubId) => {
        let state1 = { ...state };
        
        const hubIndex = state1.hubList.findIndex(hub => hub._id === hubId);
    
        if (hubIndex !== -1) {
            if ("modal" in state1.hubList[hubIndex]) {
                state1.hubList[hubIndex]["modal"] = !state1.hubList[hubIndex].modal;
            } else {
                state1.hubList[hubIndex]["modal"] = true;
            }
            setState(state1);
        }
    };
    
    useEffect(() => {
        let state1 = { ...state }
        state1?.hubList?.map((hub, key) => {
            lastEvents?.lastEvent?.devices?.map(event => {
                const eventId = event._id.split("_")
                if (eventId[0] + "_" + eventId[1] === hub._id) {
                    hub["event"] = event.events
                }
            })
        })
        setState(state1)
    }, [lastEvents])

    let filterHubs = []
    if (state.hubList.length !== 0) {

        filterHubs = state.hubList.filter(item => {
            if (item.name) {
                return searchHubs !== "" ? item._id.toLowerCase().includes(searchHubs.toLowerCase()) || item.levelId.toLowerCase().includes(searchHubs.toLowerCase()) || item.type.toLowerCase().includes(searchHubs.toLowerCase()) || item.name.toLowerCase().includes(searchHubs.toLowerCase()) : item;
            }
        })
    }
    const sortData = (type, objkey) => {
        setSortType(type)
        const sorted = SortItems(type, filterHubs, objkey)
        // console.log(sorted)
        setSortedUsers(sorted)
    }
    useEffect(() => {
        setSortedUsers(filterHubs);
        const totalPages = Math.ceil(filterHubs.length / pagination.pageSize);
        setPagination((prev) => ({ ...prev, pages: totalPages }));
    }, [state.hubList, searchHubs]);



    const startIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const paginatedData = sortedUsers.slice(startIndex, startIndex + pagination.pageSize);

    return (
        <div className='p-3'>
            <Card className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className="d-flex justify-content-between">
                    <Heading>
                        MQTT
                    </Heading>
                </CardHeader>
                <div className='col-12 mb-3 '>
                    <Select sort={false} label="Select Organization" name="organization" onClick options={rootLevel} onChange={(e) => onChangeHandler(e)} />
                </div>
                
                {state.hubList.length ?
                    <div className='col-12 mb-3'>
                        <Input label="Serach Hub" name="search" onChange={(e) => setSearchHubs(e.target.value)} />
                    </div>
                    : null}</Card>
                       {pagination &&
    <div className='d-flex w-100 justify-content-between align-items-center '>
         <div className= 'ml-2 mt-3 d-flex'>
           {startIndex + 1} to {startIndex + paginatedData.length} of {filterHubs.length} 
        </div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center m-0">
                {/* Previous button */}
                {pagination.currentPage > 1 ? (
                    <li className={"page-item px-1"}>
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                            Previous
                        </button>
                    </li>
                ) : (
                    <li className={"page-item px-1 disabled"}>
                        <button className="btn btn-light rounded-3" tabIndex="-1">Previous</button>
                    </li>
                )}

                {/* First page button */}
                <li className="page-item px-1">
                    <button 
                        onClick={() => setPagination({ ...pagination, currentPage: 1 })} 
                        className={"btn btn-light rounded-3" + (pagination.currentPage === 1 ? " active" : "")}>
                        1
                    </button>
                </li>

                {/* Ellipsis for pages not in view */}
                {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                {/* Dynamically generating buttons for nearby pages */}
                {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                    (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                    || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                    || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                ) ? (
                    <li key={pageNo} className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pageNo })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                            {pageNo}
                        </button>
                    </li>
                ) : null)}

                {/* Ellipsis for future pages */}
                {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                {/* Last page button */}
                {pagination.pages > 1 && (
                    <li className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                            {pagination.pages}
                        </button>
                    </li>
                )}

                {/* Next button */}
                {pagination.currentPage < pagination.pages ? (
                    <li className="page-item px-1">
                        <button 
                            className="btn btn-light rounded-3" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                            Next
                        </button>
                    </li>
                ) : (
                    <li className="page-item px-1 disabled">
                        <button className="btn btn-light rounded-3" tabIndex="-1">Next</button>
                    </li>
                )}
            </ul>
        </nav>
    </div>
}

            {state.loader ? <CardLoader /> :
                <InnerCard>
                    <Card className="mb-3 position-sticky" >

                        <CardHeader className="row m-0">
                            <div className='col-1 col-sm-1 col-lg-1' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('status') ? 'status-asc' : 'status-' + (sortType.includes('asc') ? 'des' : 'asc')), 'status')}>
                                <Heading className="d-flex gap-2">Status
                                    <div className='ml-2'>
                                        {sortType.includes('status') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }
                                    </div>
                                </Heading>
                            </div>

                            <div className='col-2 col-sm-2 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-id-asc' : 'text-id-' + (sortType.includes('asc') ? 'des' : 'asc')), '_id')}>

                                <Heading className="d-flex gap-2">ID
                                    <div className='ml-2'>

                                        {sortType.includes('id') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }</div>
                                </Heading>
                            </div>
                            <div className='col-3 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-nam-asc' : 'text-nam-' + (sortType.includes('asc') ? 'des' : 'asc')), 'name')}>

                                <Heading className="d-flex gap-2">Name
                                    <div className='ml-2'>

                                        {sortType.includes('nam') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }</div>
                                </Heading>
                            </div>
                            <div className='col-3 col-sm-3 px-0 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-typ-asc' : 'text-typ-' + (sortType.includes('asc') ? 'des' : 'asc')), 'type')}>

                                <Heading className="d-flex gap-2">Type
                                    <div className='ml-2'>

                                        {sortType.includes('typ') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }</div>
                                </Heading>
                            </div>
                            <div className='col-3 col-sm-3 col-lg-3' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-level-asc' : 'text-level-' + (sortType.includes('asc') ? 'des' : 'asc')), 'levelId')}>

                                <Heading className="d-flex gap-2">LevelID
                                    <div className='ml-2'>

                                        {sortType.includes('level') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }</div>
                                </Heading>
                            </div>
                            <div className='col-1 col-sm-1 col-lg-2 d-flex justify-content-center' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('date') ? 'date-asc' : 'date-' + (sortType.includes('asc') ? 'des' : 'asc')), 'created')}>

                                <Heading className="d-flex gap-2">Created
                                    <div className='ml-2'>

                                        {sortType.includes('date') ?
                                            <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                            :
                                            <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                        }</div>
                                </Heading>
                            </div>
                        </CardHeader>
                    </Card>

                    <CardBody >

                        {
                            // id ?
                            paginatedData.length ?
                                paginatedData.map((hub, index) =>
                                    <Card key={index} className="mb-2 position-sticky w-100">
                                        <CardHeader className='row  m-0 align-items-center'>
                                            <>
                                                <div className='col-1  col-sm-1 col-lg-1' onClick={() => OpenModal(index)}>
                                                    {/* <div className='align-self-center' style={{ width: 10, height: 10, borderRadius: 50, marginLeft: 10, backgroundColor: "green" }} title={"Connected"}></div> */}

                                                    {/* <div className='align-self-center' style={{ width: 10, height: 10, borderRadius: 50, marginLeft: 10, backgroundColor: "event" in hub ? hub?.event?.value === 1 ? "green" : "gray" : "gray" }} title={"event" in hub ? hub?.event?.value === 1 ? "Connected Since: " + moment(hub?.event?.created).format("ll LTS") : "Disconnected at: " + moment(hub?.event?.created).format("ll LTS") : "Disconnected at: " + "00:00:00 AM 00-00-0000"}></div> */}

                                                    <i
                                                        className="fas fa-circle"
                                                        style={{
                                                            width: 10,
                                                            height: 10,
                                                            borderRadius: 50,
                                                            marginLeft: 10,
                                                            color: "event" in hub ? hub?.event?.value === 1 ? "#2ec106" : "#d3d3d3" : "#d3d3d3"
                                                        }}
                                                        title={"event" in hub ? hub?.event?.value === 1 ? "Connected Since: " + moment(hub?.event?.created).format("ll LTS") : "Disconnected at: " + moment(hub?.event?.created).format("ll LTS") : "Disconnected at: " + "00:00:00 AM 00-00-0000"}
                                                    ></i>
                                                </div>
                                                <div className="col-2 col-sm-2 col-lg-2" onClick={() => OpenModal(hub._id)}>
                                                    {hub._id}
                                                </div>
                                                <div className="col-3 col-sm-3 col-lg-2" onClick={() => OpenModal(hub._id)}>
                                                    {hub.name}
                                                </div>
                                                <div className="col-3  col-sm-3 col-lg-2" onClick={() => OpenModal(hub._id)}>
                                                    {hub.type}
                                                </div>
                                                <div className="col-2 col-sm-3 col-lg-3" onClick={() => OpenModal(hub._id)}>
                                                    {hub.levelId}
                                                </div>
                                                <div className="col-2 col-sm-2 col-lg-2" onClick={() => OpenModal(hub._id)}>
                                                    {moment(hub.created).format("ll LTS")}
                                                </div>

                                                {hub.modal ?
                                                    <Modal hub={hub} popup={(e) => { setPopup(e); }} /> : null}
                                            </>
                                        </CardHeader>
                                    </Card>
                                )
                                :
                                <p className='ml-3'>No Hubs Available</p>
                            // : <p className='ml-3'>Select Organization</p>
                        }
                        
                        {pagination &&
    <div className='d-flex w-100 justify-content-between'>
         <div className= 'ml-2 mt-3 d-flex'>
           {startIndex + 1} to {startIndex + paginatedData.length} of {filterHubs.length} 
        </div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center px-2 pt-3">
                {/* Previous button */}
                {pagination.currentPage > 1 ? (
                    <li className={"page-item px-1"}>
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                            Previous
                        </button>
                    </li>
                ) : (
                    <li className={"page-item px-1 disabled"}>
                        <button className="btn btn-light rounded-circle" tabIndex="-1">Previous</button>
                    </li>
                )}

                {/* First page button */}
                <li className="page-item px-1">
                    <button 
                        onClick={() => setPagination({ ...pagination, currentPage: 1 })} 
                        className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                        1
                    </button>
                </li>

                {/* Ellipsis for pages not in view */}
                {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                {/* Dynamically generating buttons for nearby pages */}
                {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                    (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                    || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                    || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                ) ? (
                    <li key={pageNo} className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pageNo })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                            {pageNo}
                        </button>
                    </li>
                ) : null)}

                {/* Ellipsis for future pages */}
                {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                {/* Last page button */}
                {pagination.pages > 1 && (
                    <li className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                            {pagination.pages}
                        </button>
                    </li>
                )}

                {/* Next button */}
                {pagination.currentPage < pagination.pages ? (
                    <li className="page-item px-1">
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                            Next
                        </button>
                    </li>
                ) : (
                    <li className="page-item px-1 disabled">
                        <button className="btn btn-light rounded-circle" tabIndex="-1">Next</button>
                    </li>
                )}
            </ul>
        </nav>
    </div>
}
                    </CardBody>
                   
                </InnerCard>
            }
            {
                popup ?
                    <Mypopup title={"Event"} onClose={() => { setPopup(null) }}>
                        <div className='p-3'>
                            <code>
                                {JSON.stringify("data" in popup ? popup.data : popup.eve)}
                            </code>
                           
                        </div>
                    </Mypopup> : null
            }
        </div >

    )
}


export default MQTT
