import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card, { CardHeader, Heading } from "../../components/card/card";
import { Button, ButtonContainer } from "../../components/arraybutton/arraybutton";
import Mypopup from "../../components/popup/mypopup";
import { ConfigrationDevice, delHubs, getResource } from "../../services/utilities/events";
import { getImgUrl } from "../../services/config/serverAddresses";
import { ResponseMessageLocal } from "../../services/config/generic";
import Input from "../../components/input/input";
import { Icon } from "@mui/material";
import { Sort } from "../../functions/functions";

const Devices = (props) => {
    const history = useNavigate();
    const [loader, setLoader] = useState(false);
    const [deviceList, setDeviceList] = useState([]);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [iconPopup, setIconPopup] = useState({ visible: false, iconUrl: null, message: null });
    const [typeSearchTerm, setTypeSearchTerm] = useState("");
    const [parameterPopup, setParameterPopup] = useState({ visible: false, parameterData: null });
    
    // const [sortCriteria, setSortCriteria] = useState('name'); // Default sorting by 'name'
    // const [sortOrder, setSortOrder] = useState('asc'); 
    const [sortedUsers, setSortedUsers] = useState([]);
    const [sortType, setSortType] = useState('text-nam-asc'); //
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 50, // Number of items per page
        pages: 0 // Total number of pages
    });
    const DeviceGet = async () => {
        setLoader(true);
        try {
            const devices = await ConfigrationDevice("device");
            setDeviceList(devices);
        } catch (error) {
            // console.error("Error fetching devices:", error);
            // Handle error state or logging as needed
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        DeviceGet();
    }, []);

    const deleteAccess = async (id, key) => {
        try {
            await delHubs(id);
            setDeviceList(prevList => prevList.filter((item, index) => index !== key));
        } catch (error) {
            // console.error("Error deleting device:", error);
            // Handle error state or logging as needed
        } finally {
            setConfirmPopup(null);
        }
    };

    const userDetail = (data) => {
        history('/Configuration/Devices/AddDevicePopup', { state: data });
    };

    const handleViewIcon = async (iconSrc, _id, type) => {
        if (!iconSrc) {
            setIconPopup({ visible: true, iconUrl: null, message: "Icon source is not available" });
            return;
        }

        try {
            const iconData = await imgRenderFunc(iconSrc, _id, type);
            const iconUrl = imgReturnFunc(iconData);
            setIconPopup({ visible: true, iconUrl });
        } catch (error) {
            // console.error("Error handling icon:", error);
            setIconPopup({ visible: true, iconUrl: null, message: "Error displaying icon" });
        }
    };

    const handleCloseIconPopup = () => {
        setIconPopup({ visible: false, iconUrl: null, message: null });
    };

    const imgRenderFunc = async (src, _id, type) => {
        let image = null;
        if (src) {
            if (src.endsWith(".svg")) {
                image = await getResource(src);
                image = { icon: image, type: 'svg' };
            } else if (src.endsWith(".js")) {
                let imagecheck = await getResource(src)
                let imageFunction = eval("(" + imagecheck + ")")
                if (typeof imageFunction === 'function') {
                    image = imageFunction({ _id, type});
                }
                if (typeof image === 'object' && image !== null && 'image' in image) {
                    image = image.image;
                }
                image = { icon: image, type: 'js' }
            } else {
                image = { icon: getImgUrl + src, type: 'img' };
            }
        }
        return image;
    };

    const imgReturnFunc = (data) => {
        if (data) {
            let img = data;
            if (img.type === "svg") {
                return "data:image/svg+xml;base64," + btoa(img.icon);
            } else if (img.type === "js") {
                if (typeof img.icon === "function") {
                    const svgString = img.icon({ value: 0 }); // Generate SVG string
                    // console.log(svgString)
                    return "data:image/svg+xml;base64," + btoa(svgString); // Convert SVG string to base64
                } else {
                    // console.error("img.icon is not a function for type 'js'");
                    return null;
                }
            } else {
                return img.icon;
            }
        }
        return null;
    };

    const handleViewParameter = (parameterData) => {
        setParameterPopup({ visible: true, parameterData: JSON.stringify(parameterData, null, 2) });
    };

    const handleCloseParameterPopup = () => {
        setParameterPopup({ visible: false, parameterData: null });
    };
    

    const filteredAccessList = deviceList.filter(device => 
        device.config && device.config.type &&
        device.config.type.toLowerCase().includes(typeSearchTerm.toLowerCase())
    );
    // const filteredDeviceList = deviceList.filter((device) =>
    //     device.config && device.config.type &&
    //     device.config.type.toLowerCase().includes(typeSearchTerm.toLowerCase())
    // );

    const sortData = (type, objkey) => {
        setSortType(type);
        const sorted = Sort(type, filteredAccessList, objkey);
        setSortedUsers(sorted);
    };

    useEffect(() => {
        // Set sortedUsers when accessList or searchTerm changes
        const sorted = Sort(sortType, filteredAccessList, 'label'); // Adjust the key if needed
        setSortedUsers(sorted);
    }, [deviceList, typeSearchTerm, sortType]);
   
    useEffect(() => {
        setSortedUsers(filteredAccessList);
        const totalPages = Math.ceil(filteredAccessList.length / pagination.pageSize);
        setPagination((prev) => ({ ...prev, pages: totalPages }));
    }, [deviceList, typeSearchTerm]);

    const startIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const paginatedData = sortedUsers.slice(startIndex, startIndex + pagination.pageSize);

    return (
        <div className='mt-2 px-3'>
            <Card  className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader>
                    <div className='row'>
                        <div className='col'>
                            <Heading size={1} className="mr-3">Device</Heading>
                        </div>
                        
                        <div>
                            <div className='d-flex mr-1'>
                                <ButtonContainer>
                                    <Button onClick={() => userDetail()}>Add Device</Button>
                                </ButtonContainer>
                            </div>
                        </div>
                        <div className='col-12 mt-3'>
                <Input
                    type="text"
                    // placeholder="Search by type..."
                    label="Search Device"
                    value={typeSearchTerm}
                    onChange={(e) => setTypeSearchTerm(e.target.value)}
                />
            </div>
                    </div>
                </CardHeader>
            </Card>
            {pagination &&
    <div className='d-flex w-100 justify-content-between align-items-center '>
         <div className= 'd-flex'>
           {startIndex + 1} to {startIndex + paginatedData.length} of {filteredAccessList.length} 
        </div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center m-0">
                {/* Previous button */}
                {pagination.currentPage > 1 ? (
                    <li className={"page-item px-1"}>
                        <button 
                            className="btn btn-light rounded-3" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                            Previous
                        </button>
                    </li>
                ) : (
                    <li className={"page-item px-1 disabled"}>
                        <button className="btn btn-light rounded-3" tabIndex="-1">Previous</button>
                    </li>
                )}

                {/* First page button */}
                <li className="page-item px-1">
                    <button 
                        onClick={() => setPagination({ ...pagination, currentPage: 1 })} 
                        className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                        1
                    </button>
                </li>

                {/* Ellipsis for pages not in view */}
                {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                {/* Dynamically generating buttons for nearby pages */}
                {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                    (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                    || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                    || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                ) ? (
                    <li key={pageNo} className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pageNo })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                            {pageNo}
                        </button>
                    </li>
                ) : null)}

                {/* Ellipsis for future pages */}
                {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                {/* Last page button */}
                {pagination.pages > 1 && (
                    <li className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                            {pagination.pages}
                        </button>
                    </li>
                )}

                {/* Next button */}
                {pagination.currentPage < pagination.pages ? (
                    <li className="page-item px-1">
                        <button 
                            className="btn btn-light rounded-3" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                            Next
                        </button>
                    </li>
                ) : (
                    <li className="page-item px-1 disabled">
                        <button className="btn btn-light rounded-3" tabIndex="-1">Next</button>
                    </li>
                )}
            </ul>
        </nav>
    </div>
}
            <Card  className="mb-3 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className='row align-items-center'>
                    <div className='col-1 col-sm-1 col-lg-1'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>S.No</Heading></div>
                    {/* <div className='col-4 col-sm-3 col-lg-4'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>NameSuggetion</Heading></div> */}
                    <div className='col' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-nam-asc' : 'text-nam-' + (sortType.includes('asc') ? 'des' : 'asc')))}>
                        <Heading className="d-flex gap-2">Name Suggestion
                            <div className='ml-2'>
                                {sortType.includes('nam') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>
                    <div className='col-12 col-sm-4 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData((!sortType.includes('text') ? 'text-valu-asc' : 'text-valu-' + (sortType.includes('asc') ? 'des' : 'asc')), 'value')}>
                        <Heading className="d-flex gap-2">Type
                            <div className='ml-2'>
                                {sortType.includes('valu') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>
                    
                    {/* <div className='col-2 col-sm-2 col-lg-2'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>Type</Heading></div> */}
                    <div className='col-2 col-sm-2 col-lg-2'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>Icon</Heading></div>
                    <div className='col-1 col-sm-2 col-lg-2'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>Parameter</Heading></div>
                    <div className='col-2 col-sm-2 col-lg-1 d-flex'><Heading style={{ fontSize: window.innerWidth < 576 ? '15px' : 'inherit' }}>Action</Heading></div>
                </CardHeader>
            </Card>
            {paginatedData.length > 0 ?
                paginatedData.map((device, key) =>
                    <Card className="overflow-hidden mt-1" key={key}>
                        {/* {console.log(device)} */}
                        <CardHeader className='row align-items-center'>
                            <div className='col-1 col-sm-1 col-lg-1'>{key + 1}</div>
                            <div className='col-3 col-sm-3 col-lg-4'>
                                
                                    <div className="tooltip-content">
                                        {device?.config.name}
                                    </div>
                                </div>
                            <div className='col-2 col-sm-2 col-lg-2'>{device?.config?.type}</div>
                            <div className='col-2 col-sm-2 col-lg-2 d-flex'>
                                <ButtonContainer className="mr-2 p-2 btn-light" flat="light">
                                    <Button onClick={() => handleViewIcon(device?.config?.icon, device._id, device?.config?.type)}>VIEW</Button>
                                </ButtonContainer>
                            </div>
                            <div className='col-2 col-sm-2 col-lg-2 d-flex'>
                                <ButtonContainer className="mr-2 p-2 btn-light" flat="light">
                                    <Button onClick={() => handleViewParameter(device?.config?.parameter)}>VIEW</Button>
                                </ButtonContainer>
                            </div>
                            <div className='col-2 col-sm-2 col-lg-1 d-flex'>
                                <ButtonContainer className="p-2 btn-light" flat="light">
                                    <Button>
                                        <i className="lt lt-edit" onClick={() => userDetail(device)}></i>
                                    </Button>
                                    <Button>
                                        <i className="lt lt-trash" onClick={() => { setConfirmPopup({ data: device._id, key }) }}></i>
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </CardHeader>
                    </Card>
                )
                : <p>No devices found</p>}


{pagination &&
    <div className='d-flex w-100 justify-content-between'>
         <div className= 'ml-2 mt-3 d-flex'>
           {startIndex + 1} to {startIndex + paginatedData.length} of {filteredAccessList.length} 
        </div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm justify-content-center px-2 pt-3">
                {/* Previous button */}
                {pagination.currentPage > 1 ? (
                    <li className={"page-item px-1"}>
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                            Previous
                        </button>
                    </li>
                ) : (
                    <li className={"page-item px-1 disabled"}>
                        <button className="btn btn-light rounded-circle" tabIndex="-1">Previous</button>
                    </li>
                )}

                {/* First page button */}
                <li className="page-item px-1">
                    <button 
                        onClick={() => setPagination({ ...pagination, currentPage: 1 })} 
                        className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                        1
                    </button>
                </li>

                {/* Ellipsis for pages not in view */}
                {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                {/* Dynamically generating buttons for nearby pages */}
                {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                    (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                    || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                    || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                ) ? (
                    <li key={pageNo} className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pageNo })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                            {pageNo}
                        </button>
                    </li>
                ) : null)}

                {/* Ellipsis for future pages */}
                {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                {/* Last page button */}
                {pagination.pages > 1 && (
                    <li className="page-item px-1">
                        <button 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })} 
                            className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                            {pagination.pages}
                        </button>
                    </li>
                )}

                {/* Next button */}
                {pagination.currentPage < pagination.pages ? (
                    <li className="page-item px-1">
                        <button 
                            className="btn btn-light rounded-circle" 
                            onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                            Next
                        </button>
                    </li>
                ) : (
                    <li className="page-item px-1 disabled">
                        <button className="btn btn-light rounded-circle" tabIndex="-1">Next</button>
                    </li>
                )}
            </ul>
        </nav>
    </div>
}
            {confirmPopup && <Mypopup title={"Confirm!"} onClose={() => { setConfirmPopup(null) }}>
                <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                <div className="d-flex justify-content-center p-3">
                    <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteAccess(confirmPopup.data, confirmPopup.key)}>Yes</button>
                    <button style={{ width: 100 }} className="btn btn-light" onClick={() => { setConfirmPopup(null) }}>No</button>
                </div>
            </Mypopup>}
                {iconPopup.visible && <Mypopup title={"Icon"} onClose={handleCloseIconPopup}>
                    <div className="d-flex justify-content-center p-3">
                        {iconPopup.message ? (
                            <p>{iconPopup.message}</p>
                        ) : (
                            typeof iconPopup.iconUrl === 'string' ? (
                                <img src={iconPopup.iconUrl} alt="Device Icon" style={{ height: '300px', width: "300px", objectFit: "contain" }} />
                                
                            ) : (
                                <div className="alert alert-danger" role="alert">
                                    Error displaying icon. Please check the icon source.
                                </div>
                            )
                        )}
                    </div>

                </Mypopup>}
            {parameterPopup.visible && <Mypopup title={"Parameter"} onClose={handleCloseParameterPopup}>
            <div className="p-3" style={{ maxWidth: "800px", maxHeight: "400px", overflow: "auto" }}>
                    <pre>{parameterPopup.parameterData}</pre>
                </div>
            </Mypopup>}
        </div>
    );
};

export default Devices;
