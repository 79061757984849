import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LT from "../../assets/svgs/Ottomatically_logo_horizontal.png";
import MyDropDown, { MyDropDownBody } from "../../components/dropdown/dropdown";
import Mypopup from "../../components/popup/mypopup";
import Signal from "../../components/signals/signal";
import { signOut } from "../../services/utilities/users";
import LightDarkButton from "../../components/lightDarkButton/lightDarkButton";
import { useSelector } from "react-redux";
import Card from "../../components/card/card";
import { lastloginalert } from "../../services/utilities/events";

function DefaultHeader(props) {
  const [orgLogo, setOrgLogo] = useState(null);
  const [popup, setPopup] = useState(false);
  const [userPopup, setUserPopup] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const configuration = useSelector((state) => state.alertReducer);

  // Poll the API and fetch notifications every 10 seconds
  useEffect(() => {
    const fetchApiNotifications = async () => {
      try {
        const response = await lastloginalert(); // Fetch from API
        console.log("API Response:", response); // Log the full response
        
        const apiNotifications = response || [];
        console.log("Fetched Notifications:", apiNotifications); // Log the notifications
  
        // Limit to the last 10 notifications
        const limitedNotifications = apiNotifications.slice(0, 10);
        
        setNotifications(limitedNotifications); // Set the fetched notifications
        setNotificationCount(limitedNotifications.length); // Update count
      } catch (error) {
        console.error("Error fetching API notifications:", error);
      }
    };
  
    fetchApiNotifications(); // Fetch notifications only once when component mounts
  }, []);
  

  // Listen for live notifications from configuration.alert1
  useEffect(() => {
    console.log(notifications);
    if (configuration.alert1) {
      const { details, email, message, timestamp } = configuration.alert1;
      const updatedDetails = details.split("to an account").join(` (${email})`);
      const newNotification = {
        value: updatedDetails,
        label: message,
        timestamp: timestamp || new Date().toISOString(),
      };
  
      // Add the new notification and limit the total to 10
      setNotifications((prevNotifications) => {
        const updatedNotifications = [newNotification, ...prevNotifications];
        return updatedNotifications.slice(0, 10); // Keep only the last 10 notifications
      });
      
      setNotificationCount((prevCount) => Math.min(prevCount + 1, 10)); // Increment count but cap at 10
    }
  }, [configuration.alert1]);

  // Toggle the notification dropdown
  const toggleNotifications = () => {
    setNotificationOpen((prev) => !prev);
    if (!notificationOpen) {
      setNotificationCount(0); // Reset count when dropdown is opened
    }
  };

  const formatFacebookDate = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const differenceInSeconds = Math.floor((now - date) / 1000);

    if (differenceInSeconds < 60) {
      return `${differenceInSeconds} seconds ago`;
    } else if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60);
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (differenceInSeconds < 86400) {
      const hours = Math.floor(differenceInSeconds / 3600);
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (differenceInSeconds < 172800) {
      return "Yesterday";
    } else {
      return date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: now.getFullYear() !== date.getFullYear() ? "numeric" : undefined,
      });
    }
  };

  let history = useNavigate();
  const getCookie = (key) => {
    let name = key + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let decodedCookieParts = decodedCookie.split(";");
    for (let i = 0; i < decodedCookieParts.length; i++) {
      let c = decodedCookieParts[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  };
  document.onclick = function (e) {
    if (hover1) {
      setAlertPopup(!alertPopup);
    } else {
      setAlertPopup(false);
    }
    if (hover2) {
      setUserPopup(!userPopup);
    } else {
      setUserPopup(false);
    }
  };

  const showPopup = () => {
    setPopup(true);
  };
  const handleClick = () => {
    history("/change-password");
  };

  let { pathname } = useLocation();
  let levelId = pathname.split("/");
  levelId = levelId[levelId.length - 1];
  let orgId = levelId;
  if (orgId.match("_")) {
    let orgIdList = orgId.split("_");
    orgId = orgIdList[0];
  }
  return (
    <>
      <div className="topContainer">
        <div className="blurContainer"></div>
        <a href="#">
          <img src={orgLogo ? orgLogo : LT} alt="Linked-things Logo" />
        </a>
        <nav className={"right-btns"}>
          <MyDropDown className="position-unset">
            <button
              className={"bellbtn" + (notificationCount ? " bell-notification " : "")}
              onClick={toggleNotifications}
            >
              <i className="lt lt-notify"></i>
              {notificationCount > 0 && (
                <span className="notification-count" style={{ color: "red" }}>
                  {notificationCount}
                </span>
              )}
            </button>
          <MyDropDownBody
  className="notifDropdownContainer"
  open={notificationOpen}
  right
  style={{
    width: "400px",
    maxHeight: "500px",
    overflowY: "auto",
    padding: "10px 20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    zIndex: 1000,
  }}
>
  <h5>Notifications</h5>
  {notifications.length ? (
    notifications.map((notify, key) => (
      <Card
        key={notify._id}
        className={"bt-1"}
        style={{
          padding: "15px",
          borderBottom: "1px solid #f0f0f0",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="col">
          <b>{notify.message}</b> {/* Notification message */}
        </div>
        <div className="col">{notify.details}</div> {/* Notification details */}
        <div className="col">
          From: {notify.firstName} {notify.lastName} ({notify.email})
        </div>
        <div className="col">
          <small>{formatFacebookDate(notify.createdOn)}</small> {/* Formatted date */}
        </div>
      </Card>
    ))
  ) : (
    <div
      className="h-100 d-flex justify-content-center align-items-center"
      style={{
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.1em",
        color: "#888",
      }}
    >
      <p className="m-0">No Notifications</p>
    </div>
  )}
</MyDropDownBody>

          </MyDropDown>
          <div className="mySignll">
            <Signal connection={props.network} />
          </div>

          <MyDropDown className="position-unset" openToggle={(e) => setHover2(e)}>
            <button className="user-icon">
              <i className="lt lt-user"></i>
            </button>
            <MyDropDownBody className="userDropdownContainer" open={userPopup} right>
              <button className="userdropdown w-100 d-flex">
                <i className="mr-2 lt lt-user"></i> {getCookie("user")}
              </button>
              <button className="userdropdown w-100" onClick={() => handleClick()}>
                <i className="mr-2 lt lt-lock"></i>Change Password
              </button>
              <LightDarkButton />
              <button className="userdropdown w-100" onClick={() => showPopup()}>
                <i className="mr-2 lt lt-power"></i> Sign Out
              </button>
            </MyDropDownBody>
          </MyDropDown>
        </nav>
      </div>

      {popup ? (
        <Mypopup title="Warning!" onClose={(e) => setPopup(false)}>
          <p className="p-3"> Are You Sure Sign Out?</p>
          <div className="d-flex justify-content-center p-3">
            <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => signOut()}>Yes</button>
            <button style={{ width: 100 }} className="btn btn-light " onClick={() => { setPopup(false) }}>No</button>
          </div>
        </Mypopup>
      ) : null}
    </>
  );
}

export default DefaultHeader;
