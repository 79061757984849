/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { DeleteChildUser, GetRoots, EditUser, getHierarchy, getAdminRoles, delBranch, delBranches, putBranches, AddLevel, GetAdminRoles, Harddelroles, Harddelusers } from '../../services/utilities/events'
import Card, { CardBody, CardHeader, Heading } from '../../components/card/card'
import CardLoader from '../../components/loader/cardLoader'
import InlineLoader from '../../components/loader/inlineLoader'
import { Button, ButtonContainer } from '../../components/arraybutton/arraybutton'
import { connect, useSelector } from 'react-redux'
import { Sort, flattenArray, hslToHex } from '../../functions/functions'
import Mypopup from '../../components/popup/mypopup'
import { ResponseMessageLocal, ResponseMessageLocalpermenant } from '../../services/config/generic'
import AddUserPopup from '../../components/addUserPopup/addUserPopup'
import { Collapse, Dropdown } from 'reactstrap';
import Select from '../../components/select/select'
import ChangePasswordPopup from '../../components/changePassword/changePasswordPopup'
import { useNavigate } from 'react-router-dom'
import Input from '../../components/input/input'
import moment from 'moment/moment'



const UserManagement = (props) => {

    const history = useNavigate();
    const adminList = useSelector(state => state.fetchUserList)

    const configuration = useSelector(state => state.configuration)

    const [messagePopup, setMessagePopup] = useState(false)
    const [data, setData] = useState([])
    const [filterList, setFilterList] = useState([])
    const [confirmPopup, setConfirmPopup] = useState(false)
    const [userUpdate, setUserUpdate] = useState(true)
    const [loader, setLoader] = useState(false)
    const [levelLoader, setLevelLoader] = useState(false)
    const [isOpen, setIsOpen] = useState([]);
    const [rootLevel, setRootLevel] = useState([])
    const [addUser, setAddUser] = useState(null)
    const [msgPopup, setMsgPopup] = useState(null)
    const [branchLevel, setBranchLevel] = useState([])
    const [updatePassword, setUpdatePassword] = useState(null)
    const [roleLevel, setRoleLevel] = useState([])
    const [delbranch, setDelBranch] = useState(false)
    const [branch, setBranch] = useState({})
    const [level1, setLevel1] = useState({})
    const [del, setDel] = useState(false)
    const [hierarchy, setHierarchy] = useState(null)
    const [rolesbranch, setRoleBranch] = useState()
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [addLoader, setAddLoader] = useState(false)
    const [hierarchyLoader, setHierarchyLoader] = useState(false)
    const [searchTerm, setSearchTerm] = useState("");
    const [searchOrg, setSearchOrg] = useState('');
    const [sortOption, setSortOption] = useState('all');
    const [sortStatus, setSortStatus] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [confirmedPopup, setConfirmedPopup] = useState(false);
    const [sortCreatedOrder, setSortCreatedOrder] = useState(null);
    const [searchList, setSearchList] = useState([])
    const [sortType, setSortType] = useState('date-dsc')
    const [sortedUsers, setSortedUsers] = useState([])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        pageSize: 50, // Number of items per page
        pages: 0 // Total number of pages
    });
    const userDetail = (data) => {
        history({
            pathname: '/userDetail',
            search: '?id=' + data._id,
            state: data
        })
    }

    const [level2, setlevel2] = useState({})

    const getUsers = async () => {
        setUserUpdate(false)
        const data = [...adminList.UserLists]
        let a1 = data.map(data => {

            return data
        })
        a1.reverse()
        setFilterList(a1)
    }

    const userAddedResponse = (e) => {
        setMsgPopup(e.message)
        let filterList1 = [...filterList]
        if (e.response?.data) {
            filterList1.unshift(e.response.data)
        }
        setFilterList(filterList1)
    }
    const roleLevelGet = async (id) => {
        let response = await getHierarchy(id)
        setHierarchy(response)
        let array = []
        if (response?.levels?.length) {
            array = flattenArray(response.levels)
        }
        let branch = []
        if (array.length) {
            branch = array.map(bnch => {
                let levelName = array.find(arr => arr._id === bnch.levelId)?.locationName || ''
                if (levelName) {
                    levelName += ' > '
                }
                return ({ label: levelName + bnch.locationName, value: bnch._id })
            })
        }
        setBranchLevel(branch)
        let roles = await GetAdminRoles(id)
        let role = []
        roles.length && roles.map(rol => {
            role.push({ label: rol.name, value: rol._id })
        })
        setRoleLevel(role)
        return response
    }

    const rootLevelGet = async () => {
        let root = await GetRoots()
        let organization = []
        root.length && root.map(org => {
            organization.push({ label: org, value: org })
        })
        organization.unshift({ label: "Null", value: "" })

        setRootLevel(organization)
    }
    useEffect(() => {
        getUsers()
        rootLevelGet()
    }, [userUpdate])

    const deleteUser = async (key, id) => {
        setDeleteLoader(true)
        let data1 = [...filterList]

        let result = await DeleteChildUser(id)
        if (result.statusCode === 200) {
            data1.splice(key, 1)
        }
        setFilterList(data1)

        setDeleteLoader(null)
        setConfirmPopup(null)

    }

    const onChangeHandler = async (e, enabled, key) => {
        let userData = { ...e }
        let allUsers = [...filterList]
        userData.registered = enabled
        return await EditUser(userData, userData._id).then(async res => {
            let result = res
            if (result) {
                if (!result.statusCode) {
                    allUsers[key] = result.data
                    setFilterList(allUsers)
                }
                else {
                    let message = res.message
                    setMessagePopup(["Failure!", message, "danger"])
                }
            }
        })

    }



    const handleremove = (key, index, key2) => {
        let branch1 = branch
        branch1[key].splice(index, 1)
        toggle(key2)
        setBranch(branch1)
        return;
    }

    const deleteLevels = async (key, key1, levelId, userId) => {
        setDeleteLoader(true)
        let data1 = [...filterList]
        let result = await delBranch(levelId, userId)
        if (!result.statusCode) {
            data1[key].hierarchy.splice(key1, 1)
            setFilterList(data1)
        }
        setDel(null)
        setLevel1(data1)
        setFilterList(data1)
        setDeleteLoader(false)
    }

    const deletebranch = async (key, key1, key2, levelId, userId) => {
        setDeleteLoader(true)
        let filterList1 = [...filterList]
        let result = await delBranches(levelId, userId)
        if (result.statusCode === 200) {
            filterList1[key].hierarchy[key1].branches.splice(key2, 1)
            setFilterList(filterList1)
        }
        setDeleteLoader(false)
        setDelBranch(null)


    }

    // const collapseHandler = (key) => {
    //     let a1 = [...filterList]
    //     let row = a1
    //     key.map((val, index) => {
    //         if (key.length - 1 > index) {
    //             if (row[val].collapse) {
    //                 row[val].colorLevel = 1 + ((key.length) - (index + 1))
    //             } else {
    //                 row[val].colorLevel = 1
    //             }
    //             row = row[val].child
    //         }
    //         else if (key.length - 1 === index) {
    //             if (row[val].collapse) {
    //                 row[val].colorLevel = 1 + ((key.length) - (index + 1))
    //             } else {
    //                 row[val].colorLevel = 1
    //             }
    //             row.map((row1, index1) => {
    //                 if (index1 === val) {
    //                     row1.collapse = !(row1.collapse)
    //                 } else {
    //                     row1.collapse = false
    //                 }

    //             })
    //         }
    //     })
    //     setFilterList(a1)


    // }
    const toggle = (index) => {
        isOpen.find(open => open === index) ?
            setIsOpen(isOpen.filter(open => open !== index)) :
            setIsOpen(isOpen.concat([index]));
    };

    const roleIdReturn = (level) => {
        let branches1 = []
        if (level.branches.length) {
            level.branches.map(branch => {
                branches1.push({ levelId: branch.levelId, roleId: branch.roleId })
            })
        }
        return branches1
    }
    const branchSend = async (key, level, userId) => {
        let filterList1 = [...filterList]
        let branches = Object.values(branch)[0]
        if ("branches" in level && branches.length) {
            setAddLoader(true)
            let findBranch = branches.filter(data => data === "")
            if (!findBranch.length) {
                branches = [...branches, ...roleIdReturn(level)]
                const data = await putBranches(userId, { "branches": branches })
                if (!data.statusCode) {
                    filterList1[key] = data
                    setFilterList(filterList1)
                } else {
                }
            }
            setAddLoader(false)
            setBranch(null)
        }
    }
    const handleChangeInput = async (e) => {
        let level1 = { ...level2 }

        let name = e.name
        if (name === "_id") {
            let levelGet = await roleLevelGet(e.target.value)
            level1["roles"] = ""
            level1[name] = levelGet._id
            level1["name"] = levelGet.name
            level1["type"] = levelGet.type
        }
        else if (name === "branches") {
            level1["branches"] = [{ roleId: level1.roles, levelId: e.target.value }]
            level1["roles"] = []
        }
        else {
            level1[name] = e.target.value
        }
        setlevel2(level1)
    }
    const branchesHandler = (e, key, index) => {
        let branches1 = branch
        let branch2 = branches1[key]
        branch2[index][e.name] = e.target.value
        branches1[key] = branch2
        setBranch(branches1)
        return;

    }
    const updateLevel = async (key, id, state) => {
        console.log("Function called with key:", key, "id:", id, "state:", state); // Log input params
        let filterList1 = [...filterList];
        console.log("Initial filterList copy:", filterList1); // Log the copied list

        if (state._id && state.roles) {
            delete state.roles;
            console.log("State after deleting roles:", state); // Log state after roles deletion

            setLevelLoader(true);
            console.log("Setting level loader to true");

            const data = await AddLevel(id, state);
            console.log("Data received from AddLevel:", data); // Log the result of the AddLevel API call

            if (data.statusCode) {
                alert(data.messages);
                console.log("Error message:", data.messages); // Log error message
            } else {
                filterList1 = filterList1.map(rowdata => {
                    if (rowdata._id === data._id) {
                        rowdata = data
                    }
                    return rowdata
                });
                console.log("Updated filterList after success:", filterList1); // Log updated list
            }

            setLevelLoader(false);
            console.log("Setting level loader to false");

            setlevel2({
                roles: null,
                _id: null
            });
            console.log("Reset level2 state"); // Log state reset

            setFilterList(filterList1);
            console.log("Updated filterList set in state"); // Log the updated filterList in state

            return data;
        } else {
            alert("Id And Roles is Undefined");
            console.log("Error: Id and Roles are undefined"); // Log error if state doesn't have _id or roles
        }
        return;
    }

    const branchrole = async (id) => {
        try {
            const roles = await getAdminRoles(id)
            let rols = []
            roles.length && roles.map(rol =>
                rols.push({ label: rol.name, value: rol._id }))

            setRoleBranch(rols)
        } catch (error) {
        }
    }

    const addstage = async (key, index, id) => {
        branchrole(id)
        setHierarchyLoader(true)
        try {
            const data = await getHierarchy(id)
            setHierarchyLoader(false)
            const array = flattenArray(data.levels);
            let level = []
            if (array.length) {
                level = array.map(lvel => {
                    let levelNames = array.find(arr => arr._id === lvel.levelId)?.locationName || ''
                    if (levelNames) {
                        levelNames += ' > '
                    }
                    return ({ label: levelNames + lvel.locationName, value: lvel._id })
                })
            }
            setLevel1(level)

            let rows = branch !== null ? branch : {};

            let branches = [];
            if (key in rows) {
                branches = rows[key];
            }
            else {

            }
            branches.push({ roleId: "", levelId: "" });
            rows[key] = branches;
            toggle(index);
            setBranch(rows);
            setIsOpen([...isOpen, index + 1]);
        } catch (error) {
        }
    }
    const handleSelectChange = (event) => {
        setSearchOrg(event.target.value);
        setSearchTerm(null)
        const filteredUsers = filterList.filter(user => {
            return event.target.value !== "" ? user.hierarchy.map(level => level._id).includes(event.target.value) : user
        });
        setSearchList(filteredUsers)
    };
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const filteredData = filterList.filter(item => {
            return e.target.value !== "" ? item.firstName?.toLowerCase().includes(e.target.value?.toLowerCase()) || item.lastName?.toLowerCase().includes(e.target.value?.toLowerCase()) || item.email?.toLowerCase().includes(e.target.value?.toLowerCase()) : item;
        })
        setSearchList(filteredData)
    };

    const sortUsersByStatus = (status) => {
    };

    useEffect(() => {
        if (sortStatus) {
            sortUsersByStatus(sortStatus);
        }
        setSearchList(filterList)
    }, [filterList, sortStatus]);
    useEffect(() => {
        sortData('date-des')
    }, [searchList]);


    const deleteRoles = async (id, key) => {
        await Harddelusers(id);
        setFilterList(prevList => prevList.filter((item, index) => index !== key));
        setConfirmPopup(null);
    }




    const sortData = (type) => {
        setSortType(type)
        const sorted = Sort(type, searchList)
        setSortedUsers(sorted)
    }

    useEffect(() => {
        setSortedUsers(filterList);
        const totalPages = Math.ceil(filterList.length / pagination.pageSize);
        setPagination((prev) => ({ ...prev, pages: totalPages }));
    }, [filterList, searchList]);



    const startIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const paginatedData = sortedUsers.slice(startIndex, startIndex + pagination.pageSize);
    return (
        <div className='p-3'>
            <Card className="mb-2 position-sticky" style={{ top: 35, zIndex: '2' }}>
                <CardHeader className="d-flex justify-content-between">
                    <Heading>User Management</Heading>

                    <ButtonContainer color="primary-gradient text-white ">
                        <Button onClick={() => setAddUser("data")}>Add User</Button>
                    </ButtonContainer>
                </CardHeader>


                <div className='col-12 mb-2'>
                    <Select className="mb-3" label="Select Organization" name="organization" onClick options={rootLevel} onChange={handleSelectChange} />
                    <Input className="mb-2"
                        type="text"
                        label="Search User"
                        placeholder="Search Users"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                </div>

            </Card>
            {pagination &&
                <div className='d-flex w-100 justify-content-between align-items-center '>
                    <div className='d-flex'>
                        {startIndex + 1} to {startIndex + paginatedData.length} of {filterList.length}
                    </div>
                    <nav aria-label="Page navigation">
                        <ul className="pagination pagination-sm justify-content-center m-0">
                            {/* Previous button */}
                            {pagination.currentPage > 1 ? (
                                <li className={"page-item px-1"}>
                                    <button
                                        className="btn btn-light rounded-3"
                                        onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                                        Previous
                                    </button>
                                </li>
                            ) : (
                                <li className={"page-item px-1 disabled"}>
                                    <button className="btn btn-light rounded-3" tabIndex="-1">Previous</button>
                                </li>
                            )}

                            {/* First page button */}
                            <li className="page-item px-1">
                                <button
                                    onClick={() => setPagination({ ...pagination, currentPage: 1 })}
                                    className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                                    1
                                </button>
                            </li>

                            {/* Ellipsis for pages not in view */}
                            {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                            {/* Dynamically generating buttons for nearby pages */}
                            {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                                (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                                || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                                || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                            ) ? (
                                <li key={pageNo} className="page-item px-1">
                                    <button
                                        onClick={() => setPagination({ ...pagination, currentPage: pageNo })}
                                        className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                                        {pageNo}
                                    </button>
                                </li>
                            ) : null)}

                            {/* Ellipsis for future pages */}
                            {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                            {/* Last page button */}
                            {pagination.pages > 1 && (
                                <li className="page-item px-1">
                                    <button
                                        onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })}
                                        className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                                        {pagination.pages}
                                    </button>
                                </li>
                            )}

                            {/* Next button */}
                            {pagination.currentPage < pagination.pages ? (
                                <li className="page-item px-1">
                                    <button
                                        className="btn btn-light rounded-3"
                                        onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                                        Next
                                    </button>
                                </li>
                            ) : (
                                <li className="page-item px-1 disabled">
                                    <button className="btn btn-light rounded-3" tabIndex="-1">Next</button>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            }
            <Card className="mb-3 position-sticky" style={{ top: 35 }}>
                <CardHeader className="row m-0">
                    <div className='col-1 col-sm-1 col-lg-1'>
                        <Heading >S.No</Heading>
                    </div>
                    <div className='col-2 col-sm-3 col-lg-1' style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('name') ? 'name-asc' : 'name-' + (sortType.includes('asc') ? 'des' : 'asc'))}>
                        <Heading className="d-flex gap-2">Status
                            <div className='ml-2'>
                                {sortType.includes('name') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>

                        </Heading>

                    </div>
                    <div className='col-2 col-sm-3 col-lg-2' style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('name') ? 'name-asc' : 'name-' + (sortType.includes('asc') ? 'des' : 'asc'))}>
                        <Heading className="d-flex gap-2">Name
                            <div className='ml-2'>
                                {sortType.includes('name') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>

                        </Heading>

                    </div>
                    <div className="col-2 col-sm-3 col-lg-2" style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('email') ? 'email-asc' : 'email-' + (sortType.includes('asc') ? 'des' : 'asc'))}>
                        <Heading className="d-flex gap-2">Email
                            <div className='ml-2'>
                                {sortType.includes('email') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                        </Heading>
                    </div>
                    <div className="col-1 col-sm-2 col-lg-2">
                        <Heading>Organization</Heading>
                    </div>
                    <div className="col-1 col-sm-1 col-lg-3" style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('date') ? 'date-asc' : 'date-' + (sortType.includes('asc') ? 'des' : 'asc'))}>
                        <Heading className="d-flex gap-2">Created
                            <div className='ml-2'>
                                {sortType.includes('date') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                            {/* <Button onClick={toggleSortByCreatedDate}>
                            {sortCreatedOrder === 'asc' ? (
                                <i className="fas fa-sort-down"></i>
                            ) : (
                                <i className="fas fa-sort-up"></i>
                            )}
                        </Button> */}
                        </Heading>

                    </div>

                    <div className=" d-flex col-2 col-sm-2 col-lg-1" style={{ cursor: 'pointer' }} onClick={() => sortData(!sortType.includes('action') ? 'action-asc' : 'action-' + (sortType.includes('asc') ? 'des' : 'asc'))}>

                        <Heading className="d-flex gap-2">Action
                            <div className='ml-2'>
                                {sortType.includes('action') ?
                                    <i className={"fas fa-sort-" + (sortType.includes('asc') ? 'down' : 'up')}></i>
                                    :
                                    <i style={{ color: 'lightgrey' }} className={"fas fa-sort"}></i>
                                }
                            </div>
                            {/* <Button onClick={() => sortUsersByStatus(sortStatus === 'active' ? 'inactive' : 'active')}>
                                <i className={`fas ${sortStatus === 'active' ? ' fa-long-arrow-alt-up' : 'fa-long-arrow-alt-down'}`}></i>
                            </Button> */}
                        </Heading>
                    </div>
                </CardHeader>

            </Card>
            {
                !loader ? paginatedData.length ? paginatedData.map((b, key) =>

                    <Card key={key} className={"mb-1"} >
                        <CardHeader className="row align-items-center justify-content-between">

                            <div className='col-1 col-sm-1 col-lg-1 text-truncate'>

                                {key + 1}

                            </div>
                            <div className='col-sm-1 text-truncate'>
                                <div className={"d-inline py-1 px-0 text-white rounded-circle-px"}>
                                    {b.isdeleted ? (
                                        <i className="fas fa-circle text-muted"></i> // Added condition for isdeleted
                                    ) : (
                                        b.isCompleted ? (
                                            b.registered ? (
                                                <i className="fas fa-check-circle text-success"></i>
                                            ) : (
                                                <i className="fas fa-exclamation-circle text-danger"></i>
                                            )
                                        ) : (
                                            <i className="fas fa-exclamation-circle text-warning"></i>
                                        )
                                    )}
                                </div>
                            </div>
                            <div className='col-sm-3 col-lg-2 text-truncate'>

                                {b.firstName + " " + b.lastName}

                            </div>
                            <div className='col-sm-3 col-lg-2 text-truncate'>

                                {b.email}


                            </div>

                            <div className='col-sm-2 col-lg-2 text-truncate'>
                                <br className="d-inline d-lg-none d-md-none d-sm-none" />
                                <Button className=" btn btn-light border-0 gradient-primary3 rounded-circle-px" onClick={toggle.bind(this, key + 1)} style={{ marginBottom: '1rem' }}>Levels <i className="fa fa-angle-down"></i>  </Button>

                            </div>
                            <div className='col-sm-3 col-lg-2 text-truncate'>
                                {
                                    moment(b.created).format("ll LTS")}
                            </div>
                            <div className='col-sm-2 col-lg-2 d-flex justify-content-center'>

                                <ButtonContainer flat="light" className="p-1 btn-light">
                                    <Button color={b.registered ? "text-primary" : ''} onClick={() => onChangeHandler(b, !b.registered, key)} className="h3 m-0 align-self-center"><i className={"fa fa-toggle-" + (b.registered ? "on" : "off")}></i></Button>

                                </ButtonContainer>

                                <ButtonContainer flat="light" className="mr-2 p-2 btn-light" >
                                    <Button onClick={() => userDetail(b)}><i className='lt lt-about'></i></Button>
                                    <Button onClick={() => setUpdatePassword(b)} ><i className='lt lt-lock'></i></Button>
                                    <Button onClick={() => setAddUser(b)} ><i className='lt lt-edit'></i></Button>

                                    <Button onClick={() => { setConfirmPopup({ data: b._id, key }) }}> <i className="lt lt-trash"></i></Button>
                                    <Button onClick={() => { setConfirmedPopup({ data: b._id, key }) }}> <i className="lt lt-trash  text-danger"></i></Button>

                                </ButtonContainer>
                            </div>
                        </CardHeader>
                        <Collapse isOpen={isOpen.find(openedIndex => openedIndex === (key + 1)) ? true : false}>
                            {levelLoader ? <CardLoader /> :
                                <>
                                    <div className='row px-3 w-100 mt-3'>

                                        <div className='col-lg-6 col-md-6 mt-md-0'>
                                            <Select label="Organization" onClick options={rootLevel} name="_id" onChange={(e) => handleChangeInput(e)} />
                                        </div>

                                        <div className='col-lg-6 col-md-6 mt-md-0'>
                                            <Select label="Role" onClick options={roleLevel} disabled={roleLevel.length ? false : true} name="roles" onChange={(e) => handleChangeInput(e)} />
                                        </div>
                                        <div className="col-md-8 mt-3 ">
                                            <Select label="Branch" onClick options={branchLevel} disabled={branchLevel.length ? false : true} name="branches" onChange={(e) => handleChangeInput(e)} />

                                        </div>
                                        <div className="col-md-2 mt-3  ">
                                            <ButtonContainer>
                                                <Button onClick={() => { updateLevel(key, b._id, level2) }}>Add</Button>
                                            </ButtonContainer>
                                        </div>
                                    </div>


                                    <div className='row px-3 justify-content-center'>
                                        {b?.hierarchy.length ? b.hierarchy.map((level, key1) =>
                                            <Card key={key1} className="col-3 m-1 ">
                                                <CardHeader className="d-flex justify-content-between">
                                                    <div>

                                                        {level._id}

                                                    </div>
                                                    <div>

                                                        {level?.access?.[0]?.label}

                                                    </div>
                                                    <Button onClick={() => setDel({ key: key, key1: key1, levelId: level._id, userId: b._id })}><i className='fa fa-trash'></i></Button>
                                                </CardHeader>
                                                {addLoader ? <CardLoader /> :
                                                    <>
                                                        <CardBody>
                                                            {level.branches.length > 0 ? level.branches.map((branch1, key2) =>
                                                                <div key={key2} className='d-flex justify-content-between'>
                                                                    <Card className="col-12 m-1 ">
                                                                        <CardHeader className="d-flex justify-content-between">
                                                                            <div>

                                                                                {branch1?.level?.locationName}

                                                                            </div>
                                                                            <div>

                                                                                {branch1?.role?.name}

                                                                            </div>
                                                                            <Button onClick={() => setDelBranch({ key: key, key1: key1, key2: key2, levelId: branch1?.level._id, userId: b._id })}><i className='fa fa-trash'></i></Button>
                                                                        </CardHeader>
                                                                    </Card>
                                                                </div>
                                                            ) :
                                                                "N/A"
                                                            }
                                                            {branch ? key + "_" + key1 in branch ?
                                                                branch[key + "_" + key1].map((branches, index) =>
                                                                    <div key={index} className="row"  >
                                                                        <div className="col-md-9 mt-1">
                                                                            <Select label="AddRole" onClick options={rolesbranch} name="roleId" onChange={(e) => branchesHandler(e, key + "_" + key1, index)} />
                                                                            <div className="col-md- mt-3">
                                                                                <Select label="AddBranch" onClick options={level1} name="levelId" onChange={(e) => branchesHandler(e, key + "_" + key1, index)} />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-2">
                                                                            <Button style={{ display: 'block', marginTop: '10px', borderRadius: '50%', float: 'right' }} onClick={() => handleremove(key + "_" + key1, index, key1)} > <i class="fas fa-minus-circle"></i></Button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                : null : null}
                                                        </CardBody>
                                                        {hierarchyLoader ? <div className='justify-content-center' ><InlineLoader width="40px" /> </div> :
                                                            <ButtonContainer flat="light" className="mr-1 p-1 pt-2 btn-light">
                                                                <Button color="text-primary" onClick={() => branchSend(key, level, b._id)}><i className='far fa-paper-plane '></i></Button>
                                                                <Button color="text-primary" style={{ display: 'block', border: 'none', background: 'white', justifyContent: "center", marginTop: '10px', borderRadius: '50px', float: 'right' }} onClick={() => { addstage(key + "_" + key1, key1, level._id) }}>
                                                                    <i className='fa fa-plus text-primary'></i>
                                                                </Button>
                                                            </ButtonContainer>
                                                        }
                                                    </>
                                                }
                                            </Card>
                                        )
                                            : <p>organization Not Assign</p>}
                                    </div>
                                </>
                            }
                        </Collapse>
                    </Card>
                ) : <Heading size={2}> User not found </Heading>
                    : <CardLoader />
            }

            {
                del ? <Mypopup title={"Confirm!"} onClose={() => { setDel(null) }}>
                    <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={(e) => deleteLevels(del.key, del.key1, del.levelId, del.userId)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light " onClick={(e) => { setDel(null) }}>No</button>
                    </div>
                </Mypopup> : null
            }
            {
                delbranch ? <Mypopup title={"Confirm!"} onClose={() => { setDelBranch(null) }}>
                    <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                    {deleteLoader ? <CardLoader /> :
                        <div className="d-flex justify-content-center p-3">
                            <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={(e) => deletebranch(delbranch.key, delbranch.key1, delbranch.key2, delbranch.levelId, delbranch.userId)}>Yes</button>
                            <button style={{ width: 100 }} className="btn btn-light " onClick={(e) => { setDelBranch(null) }}>No</button>
                        </div>
                    }
                </Mypopup> : null
            }
            {
                updatePassword ? <Mypopup title={"Change Password"} onUpdatepassword={() => setUpdatePassword(null)} onClose={() => { setUpdatePassword(null) }}>
                    <ChangePasswordPopup onClose={() => { setUpdatePassword(null) }} id={updatePassword._id} onResPopup={(e) => userAddedResponse(e)} onUpdatepassword={() => setUpdatePassword(null)} />
                </Mypopup> : null
            }

            {
                addUser ? <Mypopup title={addUser?._id ? "EditUser" : "AddUser"} onAddUser={() => setAddUser(null)} onClose={() => { setAddUser(null) }}>
                    <AddUserPopup data={addUser} onResPopup={(e) => userAddedResponse(e)} onAddUser={() => setAddUser(null)} />
                </Mypopup> : null
            }
            {
                msgPopup ? <Mypopup title={msgPopup[0]} onAddUser={() => setAddUser(null)} onClose={() => { setAddUser(null) }}>
                    <div className='text-center mt-3 '>
                        <p>{msgPopup[1]}</p>
                        <br />
                        <button style={{ width: 100 }} className="btn btn-dark primary-gradient-hv" onClick={(e) => {
                            setMsgPopup(null);
                            // if (msgPopup[2] === "success") {
                            //     setUserUpdate(true)
                            // }
                        }}>Ok</button>
                    </div>
                </Mypopup> : null
            }
            {
                confirmPopup ? <Mypopup title={"Confirm!"} onClose={() => { setConfirmPopup(null) }}>
                    <p className="p-3">{ResponseMessageLocal.confirmMessageDelete}</p>
                    {deleteLoader ? <CardLoader /> :
                        <div className="d-flex justify-content-center p-3">
                            <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={(e) => deleteUser(confirmPopup.key, confirmPopup.data)}>Yes</button>
                            <button style={{ width: 100 }} className="btn btn-light " onClick={(e) => { setConfirmPopup(null) }}>No</button>
                        </div>}
                </Mypopup> : null
            }

            {confirmedPopup && (
                <Mypopup title={"Confirm!"} onClose={() => setConfirmedPopup(null)}>
                    <p className="p-3">{ResponseMessageLocalpermenant.confirmMessageHardDelete}</p>
                    <div className="d-flex justify-content-center p-3">
                        <button style={{ width: 100 }} className="btn mr-3 btn-dark primary-gradient-hv" onClick={() => deleteRoles(confirmedPopup.data, confirmedPopup.key)}>Yes</button>
                        <button style={{ width: 100 }} className="btn btn-light" onClick={() => setConfirmedPopup(null)}>No</button>
                    </div>
                </Mypopup>
            )}

            {pagination &&
                <div className='d-flex w-100 justify-content-between'>
                    <div className='ml-2 mt-3 d-flex'>
                        {startIndex + 1} to {startIndex + paginatedData.length} of {filterList.length}
                    </div>
                    <nav aria-label="Page navigation">
                        <ul className="pagination pagination-sm justify-content-center px-2 pt-3">
                            {/* Previous button */}
                            {pagination.currentPage > 1 ? (
                                <li className={"page-item px-1"}>
                                    <button
                                        className="btn btn-light rounded-circle"
                                        onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}>
                                        Previous
                                    </button>
                                </li>
                            ) : (
                                <li className={"page-item px-1 disabled"}>
                                    <button className="btn btn-light rounded-circle" tabIndex="-1">Previous</button>
                                </li>
                            )}

                            {/* First page button */}
                            <li className="page-item px-1">
                                <button
                                    onClick={() => setPagination({ ...pagination, currentPage: 1 })}
                                    className={"btn btn-light rounded-circle" + (pagination.currentPage === 1 ? " active" : "")}>
                                    1
                                </button>
                            </li>

                            {/* Ellipsis for pages not in view */}
                            {pagination.currentPage > 3 && <div className='px-2'>...</div>}

                            {/* Dynamically generating buttons for nearby pages */}
                            {pagination.pages > 2 && Array.from({ length: pagination.pages }, (_, index) => index + 1).map(pageNo => (
                                (pageNo === pagination.currentPage && pagination.currentPage > 1 && pageNo < pagination.pages)
                                || (pageNo === (pagination.currentPage - 1) && (pagination.currentPage - 1) > 1)
                                || (pageNo === (pagination.currentPage + 1) && pageNo < pagination.pages - 1)
                            ) ? (
                                <li key={pageNo} className="page-item px-1">
                                    <button
                                        onClick={() => setPagination({ ...pagination, currentPage: pageNo })}
                                        className={"btn btn-light rounded-circle" + (pagination.currentPage === pageNo ? " active" : "")}>
                                        {pageNo}
                                    </button>
                                </li>
                            ) : null)}

                            {/* Ellipsis for future pages */}
                            {pagination.currentPage < pagination.pages - 2 && <div className='px-2'>...</div>}

                            {/* Last page button */}
                            {pagination.pages > 1 && (
                                <li className="page-item px-1">
                                    <button
                                        onClick={() => setPagination({ ...pagination, currentPage: pagination.pages })}
                                        className={"btn btn-light rounded-circle" + (pagination.currentPage === pagination.pages ? " active" : "")}>
                                        {pagination.pages}
                                    </button>
                                </li>
                            )}

                            {/* Next button */}
                            {pagination.currentPage < pagination.pages ? (
                                <li className="page-item px-1">
                                    <button
                                        className="btn btn-light rounded-circle"
                                        onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}>
                                        Next
                                    </button>
                                </li>
                            ) : (
                                <li className="page-item px-1 disabled">
                                    <button className="btn btn-light rounded-circle" tabIndex="-1">Next</button>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            }
        </div >
    )
}
const mapStateToProps = (state) => ({
    configration: state.configration,
});
export default connect(mapStateToProps, null)(UserManagement)


